const anatomyConfig = {
  /*================ MALE FRONT ====================*/
  "ana_male_head_front": {
    "hover": "Front head",
    "enabled": true,
    "front": true,
  },
  "ana_male_eye_right": {
    "hover": "Right eye",
    "enabled": true,
    "front": true,
  },
  "ana_male_eye_left": {
    "hover": "Left eye",
    "enabled": true,
    "front": true,
  },
  "ana_male_ear_right": {
    "hover": "Right ear",
    "enabled": true,
    "front": true,
  },
  "ana_male_ear_left": {
    "hover": "Left ear",
    "enabled": true,
    "front": true,
  },
  "ana_male_nose": {
    "hover": "Nose",
    "enabled": true,
    "front": true,
  },
  "ana_male_mouth": {
    "hover": "Mouth",
    "enabled": true,
    "front": true,
  },
  "ana_male_neck_right_front": {
    "hover": "Front right neck",
    "enabled": true,
    "front": true,
  },
  "ana_male_neck_left_front": {
    "hover": "Front left neck",
    "enabled": true,
    "front": true,
  },
  "ana_male_chest_right_upper": {
    "hover": "Upper right chest",
    "enabled": true,
    "front": true,
  },
  "ana_male_chest_right_lower": {
    "hover": "Lower right chest",
    "enabled": true,
    "front": true,
  },
  "ana_male_chest_left_upper": {
    "hover": "Upper left chest",
    "enabled": true,
    "front": true,
  },
  "ana_male_chest_left_lower": {
    "hover": "Lower left chest",
    "enabled": true,
    "front": true,
  },
  "ana_male_abdomen_right": {
    "hover": "Right abdomen",
    "enabled": true,
    "front": true,
  },
  "ana_male_abdomen_left": {
    "hover": "Left abdomen",
    "enabled": true,
    "front": true,
  },
  "ana_male_pelvis_right": {
    "hover": "Right pelvis",
    "enabled": true,
    "front": true,
  },
  "ana_male_pelvis_left": {
    "hover": "Left pelvis",
    "enabled": true,
    "front": true,
  },
  "ana_male_pubis": {
    "hover": "Pubis",
    "enabled": true,
    "front": true,
  },
  "ana_male_shoulder_right_front": {
    "hover": "Front right shoulder",
    "enabled": true,
    "front": true,
  },
  "ana_male_shoulder_left_front": {
    "hover": "Front left shoulder",
    "enabled": true,
    "front": true,
  },
  "ana_male_arm_right_front": {
    "hover": "Front right arm",
    "enabled": true,
    "front": true,
  },
  "ana_male_arm_left_front": {
    "hover": "Front left arm",
    "enabled": true,
    "front": true,
  },
  "ana_male_elbow_right_front": {
    "hover": "Front right elbow",
    "enabled": true,
    "front": true,
  },
  "ana_male_elbow_left_front": {
    "hover": "Front left elbow",
    "enabled": true,
    "front": true,
  },
  "ana_male_forearm_right_front": {
    "hover": "Front right forearm",
    "enabled": true,
    "front": true,
  },
  "ana_male_forearm_left_front": {
    "hover": "Front left forearm",
    "enabled": true,
    "front": true,
  },
  "ana_male_wrist_right_front": {
    "hover": "Front right wrist",
    "enabled": true,
    "front": true,
  },
  "ana_male_wrist_left_front": {
    "hover": "Front left wrist",
    "enabled": true,
    "front": true,
  },
  "ana_male_fingers_right": {
    "hover": "Right fingers",
    "enabled": true,
    "front": true,
  },
  "ana_male_palm_right": {
    "hover": "Right palm",
    "enabled": true,
    "front": true,
  },
  "ana_male_fingers_left": {
    "hover": "Left fingers",
    "enabled": true,
    "front": true,
  },
  "ana_male_palm_left": {
    "hover": "Left palm",
    "enabled": true,
    "front": true,
  },
  "ana_male_thigh_right": {
    "hover": "Right thigh",
    "enabled": true,
    "front": true,
  },
  "ana_male_thigh_left": {
    "hover": "Left thigh",
    "enabled": true,
    "front": true,
  },
  "ana_male_knee_right_front": {
    "hover": "Front right knee",
    "enabled": true,
    "front": true,
  },
  "ana_male_knee_left_front": {
    "hover": "Front left knee",
    "enabled": true,
    "front": true,
  },
  "ana_male_leg_right": {
    "hover": "Right leg",
    "enabled": true,
    "front": true,
  },
  "ana_male_leg_left": {
    "hover": "Left leg",
    "enabled": true,
    "front": true,
  },
  "ana_male_ankle_right_front": {
    "hover": "Front right ankle",
    "enabled": true,
    "front": true,
  },
  "ana_male_ankle_left_front": {
    "hover": "Front left ankle",
    "enabled": true,
    "front": true,
  },
  "ana_male_foot_right_front": {
    "hover": "Right foot",
    "enabled": true,
    "front": true,
  },
  "ana_male_foot_left_front": {
    "hover": "Left foot",
    "enabled": true,
    "front": true,
  },
  /*================ FEMALE FRONT ====================*/
  "ana_female_head_front": {
    "hover": "Front head",
    "enabled": true,
    "front": true,
  },
  "ana_female_eye_right": {
    "hover": "Right eye",
    "enabled": true,
    "front": true,
  },
  "ana_female_eye_left": {
    "hover": "Left eye",
    "enabled": true,
    "front": true,
  },
  "ana_female_ear_right": {
    "hover": "Right ear",
    "enabled": true,
    "front": true,
  },
  "ana_female_ear_left": {
    "hover": "Left ear",
    "enabled": true,
    "front": true,
  },
  "ana_female_nose": {
    "hover": "Nose",
    "enabled": true,
    "front": true,
  },
  "ana_female_mouth": {
    "hover": "Mouth",
    "enabled": true,
    "front": true,
  },
  "ana_female_neck_right_front": {
    "hover": "Front right neck",
    "enabled": true,
    "front": true,
  },
  "ana_female_neck_left_front": {
    "hover": "Front left neck",
    "enabled": true,
    "front": true,
  },
  "ana_female_chest_right_upper": {
    "hover": "Upper right chest",
    "enabled": true,
    "front": true,
  },
  "ana_female_chest_right_lower": {
    "hover": "Lower right chest",
    "enabled": true,
    "front": true,
  },
  "ana_female_chest_left_upper": {
    "hover": "Upper left chest",
    "enabled": true,
    "front": true,
  },
  "ana_female_chest_left_lower": {
    "hover": "Lower left chest",
    "enabled": true,
    "front": true,
  },
  "ana_female_breast_right": {
    "hover": "Right breast",
    "enabled": true,
    "front": true,
  },
  "ana_female_breast_left": {
    "hover": "Left breast",
    "enabled": true,
    "front": true,
  },
  "ana_female_abdomen_right": {
    "hover": "Right abdomen",
    "enabled": true,
    "front": true,
  },
  "ana_female_abdomen_left": {
    "hover": "Left abdomen",
    "enabled": true,
    "front": true,
  },
  "ana_female_pelvis_right": {
    "hover": "Right pelvis",
    "enabled": true,
    "front": true,
  },
  "ana_female_pelvis_left": {
    "hover": "Left pelvis",
    "enabled": true,
    "front": true,
  },
  "ana_female_pubis": {
    "hover": "Pubis",
    "enabled": true,
    "front": true,
  },
  "ana_female_shoulder_right_front": {
    "hover": "Front right shoulder",
    "enabled": true,
    "front": true,
  },
  "ana_female_shoulder_left_front": {
    "hover": "Front left shoulder",
    "enabled": true,
    "front": true,
  },
  "ana_female_arm_right_front": {
    "hover": "Front right arm",
    "enabled": true,
    "front": true,
  },
  "ana_female_arm_left_front": {
    "hover": "Front left arm",
    "enabled": true,
    "front": true,
  },
  "ana_female_elbow_right_front": {
    "hover": "Front right elbow",
    "enabled": true,
    "front": true,
  },
  "ana_female_elbow_left_front": {
    "hover": "Front left elbow",
    "enabled": true,
    "front": true,
  },
  "ana_female_forearm_right_front": {
    "hover": "Front right forearm",
    "enabled": true,
    "front": true,
  },
  "ana_female_forearm_left_front": {
    "hover": "Front left forearm",
    "enabled": true,
    "front": true,
  },
  "ana_female_wrist_right_front": {
    "hover": "Front right wrist",
    "enabled": true,
    "front": true,
  },
  "ana_female_wrist_left_front": {
    "hover": "Front left wrist",
    "enabled": true,
    "front": true,
  },
  "ana_female_fingers_right": {
    "hover": "Right fingers",
    "enabled": true,
    "front": true,
  },
  "ana_female_palm_right": {
    "hover": "Right palm",
    "enabled": true,
    "front": true,
  },
  "ana_female_fingers_left": {
    "hover": "Left fingers",
    "enabled": true,
    "front": true,
  },
  "ana_female_palm_left": {
    "hover": "Left palm",
    "enabled": true,
    "front": true,
  },
  "ana_female_thigh_right": {
    "hover": "Right thigh",
    "enabled": true,
    "front": true,
  },
  "ana_female_thigh_left": {
    "hover": "Left thigh",
    "enabled": true,
    "front": true,
  },
  "ana_female_knee_right_front": {
    "hover": "Front right knee",
    "enabled": true,
    "front": true,
  },
  "ana_female_knee_left_front": {
    "hover": "Front left knee",
    "enabled": true,
    "front": true,
  },
  "ana_female_leg_right": {
    "hover": "Right leg",
    "enabled": true,
    "front": true,
  },
  "ana_female_leg_left": {
    "hover": "Left leg",
    "enabled": true,
    "front": true,
  },
  "ana_female_ankle_right_front": {
    "hover": "Front right ankle",
    "enabled": true,
    "front": true,
  },
  "ana_female_ankle_left_front": {
    "hover": "Front left ankle",
    "enabled": true,
    "front": true,
  },
  "ana_female_foot_right_front": {
    "hover": "Right foot",
    "enabled": true,
    "front": true,
  },
  "ana_female_foot_left_front": {
    "hover": "Left foot",
    "enabled": true,
    "front": true,
  },
  /*================ MALE BACK ====================*/
  "ana_male_head_back": {
    "hover": "Back head",
    "enabled": true,
  },
  "ana_male_neck_left_back": {
    "hover": "Back left neck",
    "enabled": true,
  },
  "ana_male_neck_center_back": {
    "hover": "Back center neck",
    "enabled": true,
  },
  "ana_male_neck_right_back": {
    "hover": "Back right neck",
    "enabled": true,
  },
  "ana_male_back_left": {
    "hover": "Left back",
    "enabled": true,
  },
  "ana_male_back_center": {
    "hover": "Center back",
    "enabled": true,
  },
  "ana_male_back_right": {
    "hover": "Right back",
    "enabled": true,
  },
  "ana_male_loin_left": {
    "hover": "Left loin",
    "enabled": true,
  },
  "ana_male_loin_center": {
    "hover": "Center loin",
    "enabled": true,
  },
  "ana_male_loin_right": {
    "hover": "Right loin",
    "enabled": true,
  },
  "ana_male_buttock_left": {
    "hover": "Left buttock",
    "enabled": true,
  },
  "ana_male_buttock_right": {
    "hover": "Right buttock",
    "enabled": true,
  },
  "ana_male_shoulder_right_back": {
    "hover": "Back right shoulder",
    "enabled": true,
  },
  "ana_male_shoulder_left_back": {
    "hover": "Back left shoulder",
    "enabled": true,
  },
  "ana_male_arm_right_back": {
    "hover": "Back right arm",
    "enabled": true,
  },
  "ana_male_arm_left_back": {
    "hover": "Back left arm",
    "enabled": true,
  },
  "ana_male_elbow_right_back": {
    "hover": "Back right elbow",
    "enabled": true,
  },
  "ana_male_elbow_left_back": {
    "hover": "Back left elbow",
    "enabled": true,
  },
  "ana_male_forearm_right_back": {
    "hover": "Back right forearm",
    "enabled": true,
  },
  "ana_male_forearm_left_back": {
    "hover": "Back left forearm",
    "enabled": true,
  },
  "ana_male_wrist_right_back": {
    "hover": "Back right wrist",
    "enabled": true,
  },
  "ana_male_wrist_left_back": {
    "hover": "Back left wrist",
    "enabled": true,
  },
  "ana_male_hand_right_back": {
    "hover": "Back right hand",
    "enabled": true,
  },
  "ana_male_hand_left_back": {
    "hover": "Back left hand",
    "enabled": true,
  },
  "ana_male_hamstring_right": {
    "hover": "Right hamstring",
    "enabled": true,
  },
  "ana_male_hamstring_left": {
    "hover": "Left hamstring",
    "enabled": true,
  },
  "ana_male_knee_right_back": {
    "hover": "Back right knee",
    "enabled": true,
  },
  "ana_male_knee_left_back": {
    "hover": "Back left knee",
    "enabled": true,
  },
  "ana_male_calf_right": {
    "hover": "Right calf",
    "enabled": true,
  },
  "ana_male_calf_left": {
    "hover": "Left calf",
    "enabled": true,
  },
  "ana_male_ankle_right_back": {
    "hover": "Back right ankle",
    "enabled": true,
  },
  "ana_male_ankle_left_back": {
    "hover": "Back left ankle",
    "enabled": true,
  },
  "ana_male_heel_right": {
    "hover": "Right heel",
    "enabled": true,
  },
  "ana_male_heel_left": {
    "hover": "Left heel",
    "enabled": true,
  },
  "ana_male_foot_right_back": {
    "hover": "Right foot",
    "enabled": true,
  },
  "ana_male_foot_left_back": {
    "hover": "Left foot",
    "enabled": true,
  },
  /*================ FEMALE BACK ====================*/
  "ana_female_head_back": {
    "hover": "Back head",
    "enabled": true,
  },
  "ana_female_neck_left_back": {
    "hover": "Back left neck",
    "enabled": true,
  },
  "ana_female_neck_center_back": {
    "hover": "Back center neck",
    "enabled": true,
  },
  "ana_female_neck_right_back": {
    "hover": "Back right neck",
    "enabled": true,
  },
  "ana_female_back_left": {
    "hover": "Left back",
    "enabled": true,
  },
  "ana_female_back_center": {
    "hover": "Center back",
    "enabled": true,
  },
  "ana_female_back_right": {
    "hover": "Right back",
    "enabled": true,
  },
  "ana_female_loin_left": {
    "hover": "Left loin",
    "enabled": true,
  },
  "ana_female_loin_center": {
    "hover": "Center loin",
    "enabled": true,
  },
  "ana_female_loin_right": {
    "hover": "Right loin",
    "enabled": true,
  },
  "ana_female_buttock_left": {
    "hover": "Left buttock",
    "enabled": true,
  },
  "ana_female_buttock_right": {
    "hover": "Right buttock",
    "enabled": true,
  },
  "ana_female_shoulder_right_back": {
    "hover": "Back right shoulder",
    "enabled": true,
  },
  "ana_female_shoulder_left_back": {
    "hover": "Back left shoulder",
    "enabled": true,
  },
  "ana_female_arm_right_back": {
    "hover": "Back right arm",
    "enabled": true,
  },
  "ana_female_arm_left_back": {
    "hover": "Back left arm",
    "enabled": true,
  },
  "ana_female_elbow_right_back": {
    "hover": "Back right elbow",
    "enabled": true,
  },
  "ana_female_elbow_left_back": {
    "hover": "Back left elbow",
    "enabled": true,
  },
  "ana_female_forearm_right_back": {
    "hover": "Back right forearm",
    "enabled": true,
  },
  "ana_female_forearm_left_back": {
    "hover": "Back left forearm",
    "enabled": true,
  },
  "ana_female_wrist_right_back": {
    "hover": "Back right wrist",
    "enabled": true,
  },
  "ana_female_wrist_left_back": {
    "hover": "Back left wrist",
    "enabled": true,
  },
  "ana_female_hand_right_back": {
    "hover": "Back right hand",
    "enabled": true,
  },
  "ana_female_hand_left_back": {
    "hover": "Back left hand",
    "enabled": true,
  },
  "ana_female_hamstring_right": {
    "hover": "Right hamstring",
    "enabled": true,
  },
  "ana_female_hamstring_left": {
    "hover": "Left hamstring",
    "enabled": true,
  },
  "ana_female_knee_right_back": {
    "hover": "Back right knee",
    "enabled": true,
  },
  "ana_female_knee_left_back": {
    "hover": "Back left knee",
    "enabled": true,
  },
  "ana_female_calf_right": {
    "hover": "Right calf",
    "enabled": true,
  },
  "ana_female_calf_left": {
    "hover": "Left calf",
    "enabled": true,
  },
  "ana_female_ankle_right_back": {
    "hover": "Back right ankle",
    "enabled": true,
  },
  "ana_female_ankle_left_back": {
    "hover": "Back left ankle",
    "enabled": true,
  },
  "ana_female_heel_right": {
    "hover": "Right heel",
    "enabled": true,
  },
  "ana_female_heel_left": {
    "hover": "Left heel",
    "enabled": true,
  },
  "ana_female_foot_right_back": {
    "hover": "Right foot",
    "enabled": true,
  },
  "ana_female_foot_left_back": {
    "hover": "Left foot",
    "enabled": true,
  }
}
const spotBaseConfig = {
  "size": 50,
  "enabled": true,
}
const spotDanger = {
  "outline": "#cc2900",
  "upColor": "#ff5c33",
}
const spotSuccess = {
  "outline": "#379558",
  "upColor": "#6ac88b",
}

export {
  anatomyConfig,
  spotBaseConfig,
  spotDanger,
  spotSuccess,
}
