<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="754px" height="1370px" viewBox="-13.5 0 754 1370" xml:space="preserve">
    <image overflow="visible" width="727" height="1370" href="/assets/img/rtm/male-front.png"></image>
    <path id="ana_male_head_front" class="male-front-head" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M418.334,70C416.668,38,382,19,363,19c-30.833,0-50.167,31.5-53.167,44.5c-1.915,8.295-2.833,23.5-2.5,28.167s1,12.333,0.667,16.167c2.04,7.695,6.667,23,6.667,33c0.667,5.167,1.167,12.5,3.333,18.833c3,4,22.5,23.333,44.167,23.333s36.5-8.667,45.708-23c2.625-5.625,5-15.25,4.75-18.625c-0.708-5.125,4.708-28.042,5.709-32.708C419.001,101.334,420,102,418.334,70z"/>
    <path id="ana_male_eye_right" class="male-front-eye-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M340.5,102.25c8.561,0,14.5-4.197,14.5-9.375s-5.939-9.375-14.5-9.375S325,87.697,325,92.875S331.939,102.25,340.5,102.25z"/>
    <path id="ana_male_eye_left" class="male-front-eye-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M388.5,102.25c8.561,0,15.5-4.197,15.5-9.375s-6.939-9.375-15.5-9.375S374,87.697,374,92.875S379.939,102.25,388.5,102.25z"/>
    <path id="ana_male_ear_right" class="male-front-ear-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M299.833,106c-4,4-1.833,17-0.833,20.667s5.833,14.667,7.167,15.833s5.167,4.833,8.5-1.667c0-10-4.627-25.305-6.667-33C307,106,303.833,102,299.833,106z"/>
    <path id="ana_male_ear_left" class="male-front-ear-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M412.625,141.375c3.75,6.375,8.875,3.25,10-1.75s7.625-7.875,6.75-23.625s-8.041-11.667-11.041-7.333C417.333,113.333,411.917,136.25,412.625,141.375z"/>
    <path id="ana_male_nose" class="male-front-nose" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M355.375,105.25c-0.375,3.125-5,6.375-5.625,13.125c-0.438,4.731,6.25,7.5,10.25,6.5c5,2.625,6.75,0.625,9.875-0.625c5.75,0.875,8-3.25,8-7.75s-4.375-6.75-4.875-12.25s-3.375-7.625-3.125-13.5s-2.375-9.875-6.086-9.875c-5.21,0-6.289,7.875-5.914,10.625S355.75,102.125,355.375,105.25z"/>
    <path id="ana_male_mouth" class="male-front-mouth" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M374.25,133.25c-2.256-2.723-6.231-1.652-7.875-0.75c-0.882,0.484-3.5,0.875-5.125-0.375s-6.125-0.125-7.375,1.625s-11.75,5.125-12.625,8.125s8.625,3.25,11,4.125s4.5,3.75,13.125,3.75s10.966-2.787,13.25-3.25s8.125-1.125,8.5-3.5S377.875,137.625,374.25,133.25z"/>
    <path id="ana_male_neck_right_front" class="male-front-neck" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 362.167 183 C 340.5 183 321 163.667 318 159.667 C 320.167 166 319.5 189.5 318.75 205 C 310.25 220.25 278.75 229 270.75 232.5 C 272.792 234.155 281.445 239.098 291.607 242.008 C 296.793 243.493 302.373 244.448 307.667 244.167 C 323.334 243.334 348.834 242.001 353 247.834 C 355.068 250.729 358.983 252.228 362.871 252.271 Z"></path>
    <path id="ana_male_neck_left_front" class="male-front-neck" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 362.871 252.271 C 366.818 252.314 370.736 250.856 372.667 247.834 C 376.5 241.834 410.695 241.589 423.5 243.501 C 428.45 244.24 433.333 244.311 437.938 243.864 C 448.914 242.798 458.311 238.786 463.28 233.847 C 454.391 233.928 444.756 228.652 432.25 223.126 C 416.125 216 407.625 207.25 407 204.5 C 406.375 201.75 407.125 170 407.875 160 C 398.667 174.333 383.834 183 362.167 183 Z"></path>
    <path id="ana_male_chest_right_upper" class="male-front-chest" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="m 362.871,252.271 c -3.888,-0.043 -7.803,-1.542 -9.871,-4.437 -4.167,-5.833 -29.667,-4.5 -45.333,-3.667 -5.294,0.281 -10.873,-0.674 -16.059,-2.159 -8.004,3.48 -46.033,26.426 -52.127,58.308 -0.459,2.402 -0.744,4.852 -0.814,7.351 -0.45186,16.11641 -0.42151,46.64661 -0.42151,46.64661 125.13947,-2.1194 0,0 125.13947,-2.1194 z"></path>
    <path id="ana_male_chest_left_upper" class="male-front-chest" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="m 488.0754,354.31648 c 0,0 -0.89838,-52.35363 -1.57536,-59.31548 -2.018,-20.749 -37.75,-48.25 -48.562,-51.137 -4.605,0.447 -9.488,0.376 -14.438,-0.363 -12.805,-1.911 -47,-1.667 -50.833,4.333 -1.931,3.022 -5.849,4.48 -9.796,4.437 l 0.51396,99.92321 c 124.6904,2.12227 -4e-5,0 124.6904,2.12227 z"></path>
    <path id="ana_male_chest_right_lower" class="male-front-chest" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="m 238.24549,354.31261 c 0,0 0.3486,31.61408 0.25651,39.07539 0.383,-0.096 9.666,25.111 12.166,30.778 2.5,5.667 5.082,17.834 8.582,24.584 8.25,7.75 46.75,25.25 73.25,18.25 13.109,-3.463 22.18,-5.027 31.45,-4.953 l -0.56504,-109.85379 c -125.13947,2.1194 0,0 -125.13947,2.1194 z"></path>
    <path id="ana_male_chest_left_lower" class="male-front-chest" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="m 363.95,462.047 c 9.47,0.075 19.148,1.861 33.55,5.081 28.5,6.372 52.668,-2.794 73.084,-27.211 1.25,-3.25 4.75,-11.75 5.333,-15 0.583,-3.25 2.667,-6.999 4.084,-9.749 1.417,-2.75 7.455,-21.675 8.005,-21.176 0.28217,-5.60223 0.0694,-39.67652 0.0694,-39.67652 -124.69044,-2.12227 0,0 -124.6904,-2.12227 z"></path>
    <path id="ana_male_abdomen_right" class="male-front-abdomen" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 363.95 462.047 C 354.68 461.973 345.609 463.537 332.5 467 C 306 474 267.5 456.5 259.25 448.75 C 262.75 455.5 261.25 460.75 263 466.5 C 264.75 472.25 268 487.834 263.5 508.001 C 259 528.168 261.833 543.667 263 548.167 C 263.785 551.196 265.326 553.168 264.419 556.98 C 276 568.5 294.834 591.5 364.917 591.5 Z"></path>
    <path id="ana_male_abdomen_left" class="male-front-abdomen" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 364.917 591.5 C 435 591.5 451.334 571.002 463.667 558.001 C 462.001 553.501 463.166 546.001 466.166 536.834 C 469.166 527.667 462.667 492.167 462.333 484.001 C 461.999 475.835 464.834 462.501 465.084 456.417 C 465.334 450.333 469.334 443.167 470.584 439.917 C 450.168 464.334 426 473.5 397.5 467.128 C 383.098 463.908 373.42 462.122 363.95 462.047 Z"></path>
    <path id="ana_male_pelvis_right" class="male-front-pelvis" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 364.917 591.5 C 294.834 591.5 276 568.5 264.419 556.98 C 263.979 558.832 262.961 561.117 261 564.168 C 258.292 568.382 255.991 579.659 254.327 591.5 C 264.667 600.527 310.537 639.439 338.411 674.136 C 342.93 672.055 353.277 670.723 363.956 670.706 Z"></path>
    <path id="ana_male_pelvis_left" class="male-front-pelvis" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 363.956 670.706 C 372.785 670.693 381.84 671.578 388.017 673.683 C 416.231 640.653 462.981 602.637 473.666 594.168 C 472.666 580.502 465.332 562.501 463.666 558.001 C 451.334 571.002 435 591.5 364.917 591.5 Z"></path>
    <path id="ana_male_pubis" class="male-front-pubis" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M338.412,674.136c7.636,9.505,13.921,18.693,17.755,26.864c1-2.167,2.75-2.833,6.833-3.167s5.75,0.834,6.917,1.584c3.8-7.69,10.229-16.519,18.101-25.734C374.368,669.031,346.667,670.334,338.412,674.136z"/>
    <path id="ana_male_shoulder_right_front" class="male-front-shoulder-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M239.48,300.316c6.094-31.882,44.123-54.828,52.127-58.308c-10.162-2.91-18.816-7.853-20.857-9.508c-8,3.5-15.5,2-26.75,4.25S202.5,250,190.5,274.5s-9.5,57-9.25,65.75c0.034,1.202,0.012,2.258-0.058,3.222C194.058,328.083,224.9,324.345,239.48,300.316z"/>
    <path id="ana_male_shoulder_left_front" class="male-front-shoulder-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M486.5,295c13.5,30.001,46.022,30.211,58.595,48.439c-0.768-3.438-1.004-7.947-0.345-14.439c1.931-19.007-4.875-52.125-17.875-68.5s-53.125-26.75-63.595-26.654c-4.969,4.939-14.366,8.951-25.342,10.017C448.75,246.75,484.482,274.251,486.5,295z"/>
    <path id="ana_male_arm_right_front" class="male-front-arm-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M238.667,307.667c0.07-2.499,0.354-4.949,0.814-7.351c-14.58,24.029-45.423,27.768-58.288,43.156c-0.437,6.049-2.914,8.093-7.442,14.778C168.5,366,158.5,397.5,155,409.5c-0.507,1.738-0.896,3.229-1.221,4.551c-1.413,17.735,10.718,25.876,24.421,31.618c11.394,4.774,24.501,8.306,33.45,1.543c0.711-1.544,1.634-3.368,2.85-5.712c3.5-6.75,23.363-47.953,24.001-48.111C238.669,379.777,237.667,343.334,238.667,307.667z"/>
    <path id="ana_male_arm_left_front" class="male-front-arm-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M549.573,445.669c14.284-5.985,25.869-14.57,23.177-33.919c-1.625-11.25-17.875-51.25-22-57.25c-2.265-3.294-4.53-6.027-5.655-11.061C532.522,325.211,500,325.001,486.5,295c1.167,12.001,2.178,85.65,1.506,98.992c0.108,0.098,20.827,42.675,23.494,48.175C520.012,455.281,536.009,451.353,549.573,445.669z"/>
    <path id="ana_male_elbow_right_front" class="male-front-arm-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M178.2,445.669c-13.704-5.742-25.834-13.883-24.421-31.618c-1.917,7.803-1.51,9.506-8.779,18.699c-5.907,7.47-15.794,29.063-22.538,48.927c15.882-28.244,68.495,4.695,75.547,19.871c6.154-16.332,11.13-43.69,11.49-47.172c0.245-2.366,0.814-4.26,2.15-7.163C202.702,453.975,189.594,450.443,178.2,445.669z"/>
    <path id="ana_male_elbow_left_front" class="male-front-elbow-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M606,485.25c-2.028-7.858-4.954-16.438-9.03-24.074c-4.97-9.31-16.414-30.066-17.72-32.176c-3.25-5.25-5.336-9.194-6.5-17.25c2.692,19.349-8.893,27.934-23.177,33.919c-13.564,5.684-29.562,9.612-38.073-3.502c2.667,5.5,7,11.333,7,17.333c0,1.363,1.692,13.781,4.385,25.354c2.187,9.396,5.372,18.235,6.115,20.146C527.5,492,591.5,448,606,485.25z"/>
    <path id="ana_male_forearm_right_front" class="male-front-forearm-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M122.462,481.677c-2.96,8.722-5.318,17.111-6.462,23.823c-2.028,11.896-8.779,39.212-16.707,62.487c-1.735,5.094-3.563,9.992-5.337,14.495c1.722,9.015,32.508,23.476,42.632,18.606c1.457-2.714,2.764-5.01,3.745-6.587c4.667-7.5,11.917-19.251,24.917-35.251s25.5-39.75,32-55.75c0.255-0.629,0.508-1.285,0.76-1.953C190.958,486.372,138.345,453.433,122.462,481.677z"/>
    <path id="ana_male_forearm_left_front" class="male-front-forearm-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M632.833,581.061c-2.89-7.644-5.897-16.096-8.083-21.561c-4-10-12.75-51-18.75-74.25C591.5,448,527.5,492,529,505c7,18,35.75,60.25,40.375,65.875s16.49,23.007,19.5,28.25C595.414,609.279,634.667,590.667,632.833,581.061z"/>
    <path id="ana_male_wrist_right_front" class="male-front-wrist-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M93.956,582.482c-5.112,12.975-9.774,22.651-10.456,24.143c-0.886,1.939-1.456,3.337-2.977,4.62c9.057,0.416,28.988,8.686,43.015,19.44c2.127-7.809,8.37-20.88,13.05-29.598C126.464,605.958,95.678,591.497,93.956,582.482z"/>
    <path id="ana_male_wrist_left_front" class="male-front-wrist-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M648.75,611.25c-8.5-4-5.75-8.25-9.5-15c-1.7-3.061-4.019-8.847-6.417-15.189c1.834,9.606-37.419,28.219-43.958,18.064c1.544,2.689,5.188,10.48,8.506,17.668c3.15,6.824,6.007,13.104,6.494,13.957C618.75,618.834,640.333,610.666,648.75,611.25z"/>
    <path id="ana_male_fingers_right" class="male-front-hand-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 67.75 616.375 C 54.375 620.125 34.625 636.625 30 639.625 C 25.375 642.625 22.25 648 18.125 650.125 C 14 652.25 14 658.75 18.125 660.625 C 22.25 662.5 27.75 660.75 31.125 659.125 C 34.5 657.5 40.167 650.668 46.625 648.625 C 50.413 647.427 54.25 647.125 54.25 648.75 C 54.25 650.375 45.75 671.125 45.125 674.25 C 44.5 677.375 41.25 688.125 39.25 695.375 C 37.25 702.625 33.75 716.625 32.5 724.625 C 31.25 732.625 33.375 736.375 37.625 737.25 C 41.875 738.125 45.5 729.625 46.271 726.625 C 47.042 723.625 49.125 713.875 50.25 711.125 C 51.375 708.375 56.875 692.375 58.25 689.125 C 59.625 685.875 60.625 680.5 62.625 681.375 C 64.625 682.25 62.25 687.25 60.875 691.125 C 59.5 695 53.75 715.875 53 719.75 C 52.25 723.625 48 739.5 47.75 742.25 C 47.5 745 45.875 751 50.5 752.75 C 55.125 754.5 58.25 750.875 60 747.125 C 61.75 743.375 65.375 729.5 67.375 721 C 69.375 712.5 73.125 701.5 74.5 697 C 75.875 692.5 76.625 689 78.375 689.125 C 80.125 689.25 79.875 691.625 79.125 693.875 C 78.375 696.125 73 714.5 72 719.5 C 71 724.5 67.75 735.625 66.625 739.875 C 65.5 744.125 64.875 749.125 69.125 750.625 C 73.375 752.125 76 749.125 77.875 745.875 C 79.75 742.625 85.75 724.375 87.244 718.75 C 88.738 713.125 92 700.25 93.375 696.375 C 94.75 692.5 95.875 690.75 97 690.875 C 98.125 691 97.25 693.5 95.875 697.875 C 94.5 702.25 90.5 716.375 88.75 722.875 C 87 729.375 86.5 732.5 88.75 734.875 C 91 737.25 95.833 734.334 97 732.334 C 98.167 730.334 100 721.334 102.667 716.001 C 104.343 712.649 106.336 704.755 109.197 696.62 C 110.888 691.812 113.533 686.921 114.833 682.834 C 89.091 694.817 33.408 681.302 67.75 616.375 Z"></path>
    <path id="ana_male_palm_right" class="male-front-hand-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 114.833 682.834 C 118.333 671.834 119.166 664.001 121.833 654.334 C 124.5 644.667 122 642.667 122.833 634.167 C 122.929 633.192 123.177 632.011 123.538 630.686 C 109.511 619.931 89.58 611.662 80.523 611.246 C 78.612 612.857 75.198 614.287 67.75 616.375 C 32.968 681.669 90.577 695.018 114.833 682.834 Z"></path>
    <path id="ana_male_fingers_left" class="male-front-hand-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 610.375 679.125 C 612.625 688 617.375 697.5 618.875 702 C 620.375 706.5 628.278 731.364 631.5 734 C 634.25 736.25 639 734.75 639.75 731.25 C 640.5 727.75 638.125 720.375 637.25 717.125 C 636.375 713.875 631.625 697.875 630.75 695.375 C 629.875 692.875 628.75 690.25 630.5 690.25 C 632.25 690.25 632.625 693 633.75 695.875 C 634.875 698.75 639.625 715.375 640.625 720 C 641.625 724.625 645.125 737 646.875 741.75 C 648.625 746.5 651.875 751.75 655.875 751.5 C 659.875 751.25 660.75 746.75 661 743.125 C 661.25 739.5 655.125 719.625 654.625 715.5 C 654.125 711.375 649.25 696.25 648.5 694.25 C 647.75 692.25 647.125 689.25 649.125 689.125 C 651.125 689 652 694.75 652.875 697.75 C 653.75 700.75 662.625 729.625 663.125 733.25 C 663.625 736.875 665.75 747.75 669.125 751 C 671.869 753.643 674.75 754.875 677.75 751.875 C 680.75 748.875 680 741.875 678.625 736.625 C 677.25 731.375 674 715.5 673.125 711.625 C 672.25 707.75 666.75 690.75 665.875 687.625 C 665 684.5 663.75 682.25 664.75 681.875 C 665.75 681.5 667 683 668.25 687.125 C 669.5 691.25 674.875 707.625 676.625 712.625 C 678.375 717.625 678.125 724.25 680.75 730 C 683.375 735.75 687.75 737.625 691.375 737.125 C 695 736.625 695.652 729.734 695.75 727 C 695.848 724.266 691 706.5 689.5 699.625 C 688 692.75 684.25 683 683 676.625 C 681.75 670.25 675.625 653.25 674.375 650.625 C 673.125 648 673.75 645.875 676.875 646.75 C 680 647.625 686.125 649.375 689.875 654.375 C 693.625 659.375 700.75 661.125 703.25 661.375 C 705.75 661.625 711.75 661.75 712.5 655 C 713.25 648.25 705 645 702.25 641.25 C 699.5 637.5 684.75 629.75 680.5 626.75 C 678.375 625.25 672.562 622.375 666.219 619.375 C 659.876 616.375 653 613.25 648.75 611.25 C 705.503 681.451 637.436 690.505 610.375 679.125 Z"></path>
    <path id="ana_male_palm_left" class="male-front-hand-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 648.75 611.25 C 640.333 610.666 618.75 618.834 603.875 630.75 C 604.875 632.5 603 637.875 604 647 C 605 656.125 608.125 670.25 610.375 679.125 C 636.77 689.928 705.669 683.199 648.75 611.25 Z"></path>
    <path id="ana_male_thigh_right" class="male-front-thigh-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M254.327,591.5c-2.021,14.389-3.102,29.611-2.827,34c0.5,8-6.5,46-11.5,70c-3.981,19.107-12.131,56.915-14.375,92.478c-0.575,9.105,0.172,18.063,0.375,26.522c0.845,35.062,9.541,55.489,16.139,69.427c35.654,13.2,53.799,56.767,88.484,34.358c2.478-11.204,8.03-39.965,9.627-52.285c1.75-13.5,10.083-66.333,11.815-88.167s1.269-38.833,0.435-43.166s-0.167-12.667-0.417-21.334s3.083-10.166,4.083-12.333c-3.834-8.171-10.12-17.359-17.755-26.864C310.538,639.439,264.667,600.527,254.327,591.5z"/>
    <path id="ana_male_thigh_left" class="male-front-thigh-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M388.018,673.683c-7.872,9.216-14.301,18.044-18.101,25.734c1.167,0.75,3.083,5.083,4.333,8.083s1,20.75-0.25,31.5s1.5,59.75,3.75,71s8.417,55.334,10.084,67.001s5.166,31.5,7.166,39.833c36.334,25.833,52.479-20.023,89.334-33.168c5.667-10,13.999-27.333,15.999-52.333c0.874-10.926,1.603-27.168,0.824-43.078c-1.002-20.493-3.844-40.436-5.157-47.754c-2.333-13-14.834-82.834-17-92.667s-4.333-40-5.333-53.666C462.981,602.637,416.231,640.652,388.018,673.683z"/>
    <path id="ana_male_knee_right_front" class="male-front-knee-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M242.139,883.927c1.212,2.56,2.353,4.901,3.361,7.073c6.5,14,6,37.5,6.5,61c0.078,3.657,0.262,7.679,0.348,11.921c10.591,44.449,51.024,21.223,68.904,3.938c0.325-1.35,0.929-2.658,1.373-3.483c0.875-1.625,2.125-10.625,3.375-16.625s2-18.5,4-26.75c0.175-0.721,0.386-1.643,0.623-2.715C295.938,940.693,277.793,897.127,242.139,883.927z"/>
    <path id="ana_male_knee_left_front" class="male-front-knee-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M395,916.834c2,8.333,4.333,14.167,4.333,24s4,22.167,5.167,25c17.417,18.167,61,46.833,69.25-8.834c0-11.5,3.25-39.334,3.584-50.334s1.333-13,7-23C447.479,896.811,431.334,942.667,395,916.834z"/>
    <path id="ana_male_leg_right" class="male-front-leg-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M252.348,963.921c0.085,4.202,0.072,8.622-0.239,13.122c-1.393,20.15-4.799,41.913-4.109,52.957c1,16,4.5,62,7.5,83s6.875,83,7.125,87.5c0.06,1.082,0.008,2.26-0.107,3.478c6.992-11.484,36.463-9.869,44.754-6.101c-1.079-3.858-2.297-10.522-2.438-15.043c-0.167-5.333,7.5-47.167,8.333-58.333s3.667-29.5,4.333-33.333s5.75-17.168,9.5-25.918s3.5-20,2.5-27.25s-3.75-45.75-4.5-51.375s-2.25-13.125-3.5-15.125c-0.615-0.984-0.563-2.333-0.248-3.642C303.372,985.144,262.939,1008.37,252.348,963.921z"/>
    <path id="ana_male_leg_left" class="male-front-leg-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M404.5,965.834c1.167,2.833-1.25,16.416-4.25,33.916s-4.083,48.751-3.083,56.751s9.667,28.833,11.833,35s0.667,8.833,2,20.833s7.167,47.334,9,59s1.5,21-0.667,27.167C426,1194,462,1191.5,465.5,1207c-0.75-4.25-1.75-10-1-22.25s5-60.25,8.25-87.75s6.75-82,4.5-96.5s-3.5-32-3.5-43.5C465.5,1012.667,421.917,984.001,404.5,965.834z"/>
    <path id="ana_male_ankle_right_front" class="male-front-ankle-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M262.518,1203.978c-0.363,3.847-1.388,8.108-1.768,11.147c-0.5,4,2.125,8.625,1.375,15.875c-0.034,0.332-0.091,0.67-0.146,1.008c12.665-4.423,40.242,8.668,48.998,21.075c1.177-7.814,1.063-15.23-0.478-19.082c-1.667-4.166-2.167-7.167-0.833-12.5s-0.667-18.667-1.833-21.834c-0.178-0.482-0.368-1.097-0.562-1.79C298.981,1194.108,269.51,1192.493,262.518,1203.978z"/>
    <path id="ana_male_ankle_left_front" class="male-front-ankle-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M419.333,1198.501c-2.167,6.167-3.166,21-2.666,22.667s0.833,9.333-1,13.499s-1.667,13.334-0.667,21.5c6-13.583,37-29.917,50-23.667c-2-5.5-2.25-5.75-1-9.25s2.25-12,1.5-16.25C462,1191.5,426,1194,419.333,1198.501z"/>
    <path id="ana_male_foot_right_front" class="male-front-foot-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M261.979,1232.008c-1.15,7.047-6.68,15.393-10.854,23.742c-4.375,8.75-13,19.375-21,28.25s-10.375,26.375-10.125,29.5s3.125,5.875,6.125,5.5c0,1.125,1,2.875,4.25,2.5c0.25,2,0,6.25,8.25,5c4,4.875,7.875,4.625,10.75,1.75c5.292,6.314,10.383,6.492,15.75,5.809c4.375-0.558,11.125-7.809,12.25-10.559s2.25-3.875,5.875-6.75c1.972-1.563,3.795-4.086,5.156-8.824c1.141-3.973,1.957-10.098,2.261-12.758c0.667-5.833,0.667-10.834,4.5-21.334c8.667-3.667,14-10.333,15.5-18.833c0.113-0.642,0.215-1.28,0.311-1.918C302.221,1240.676,274.645,1227.585,261.979,1232.008z"/>
    <path id="ana_male_foot_left_front" class="male-front-foot-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M415,1256.167c1,8.166,12,15,15,16.5s3,4.167,3.833,7s2.834,10.667,3.834,21s6.25,15.749,8.666,17.666s2.834,3,3.667,4.667s3.417,6.083,11.167,9.75s14.999-1.167,16.749-4.75c4.5,4.5,11.084,0.416,12.25-2.084c4.916,1.416,7.834-3.25,7.917-5.166c1.583,0.334,3.584-1.082,4.25-2.582c0.833,0.334,2.5,0.666,5-3.334s-3-17.5-4.167-21.667s-9.666-14.833-16.333-21.833s-7.833-11.333-12.5-18.667S467,1238,465,1232.5C452,1226.25,421,1242.584,415,1256.167z"/>
    <g v-if="!sideBySide">
      <image overflow="visible" x="597" y="1026" width="149" height="274" href="/assets/img/rtm/male-back.png"></image>
      <rect x="597" y="1026" fill="transparent" stroke="#6a6a6a" vector-effect="non-scaling-stroke" width="149" height="274"/>
      <rect class="gob" x="597" y="1026" fill="#72B8FC" stroke="#6a6a6a" vector-effect="non-scaling-stroke" width="149" height="274"/>
    </g>
    <g id="anaspotsa"></g>
  </svg>
</template>

<script>
export default {
  name: 'MaleFront',
  props: {
    sideBySide: Boolean,
  },
}
</script>
