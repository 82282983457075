<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="754px" height="1370px" viewBox="-13.5 0 754 1370" xml:space="preserve">
    <image overflow="visible" width="727" height="1370" href="/assets/img/rtm/male-back.png"></image>
    <path id="ana_male_head_back" class="male-back-head" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M412.806,156.076c3.058-18.988,9.442-66.107,10.527-83.743C424.666,50.667,394.333,17,362,17c-27.334,0-58.5,32-58,52.667c0.19,7.875,2,33,2.333,36.333c0.239,2.389,4.332,32.016,7.459,49.645C324,176.667,402.611,176.486,412.806,156.076z"/>
    <path id="ana_male_neck_left_back" class="male-back-neck" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 351.444 170.878 C 333.679 169.324 317.98 164.268 313.793 155.644 C 315.028 162.606 316.113 167.697 316.75 168.499 C 318.305 170.457 319.68 196.863 317.25 199.999 C 309.445 210.072 285.775 220.791 268.042 227.499 C 277.316 224.936 310.401 223.965 346.656 223.987 Z"></path>
    <path id="ana_male_neck_center_back" class="male-back-neck" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 369.36 171.277 C 363.389 171.523 357.304 171.391 351.444 170.878 L 346.656 223.987 C 356.005 223.993 365.565 224.065 374.982 224.193 Z"></path>
    <path id="ana_male_neck_right_back" class="male-back-neck" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 374.982 224.193 C 414.523 224.73 451.541 226.251 459.833 228 C 431.833 218.5 410.834 200.667 409.834 198.5 C 408.834 196.333 410 167.833 411.334 164 C 411.582 163.287 412.107 160.416 412.806 156.076 C 408.173 165.351 389.411 170.449 369.36 171.277 Z"></path>
    <path id="ana_male_back_left" class="male-back-back" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 346.655 223.988 C 310.4 223.966 277.316 224.938 268.041 227.501 C 228.583 249.001 223.499 296.251 222.499 302.001 C 221.499 307.751 222.999 328.251 224.749 338.751 C 226.499 349.251 232.999 368.334 229.374 405.126 C 230.499 405.126 230.874 408.626 231.249 411.251 C 231.624 413.876 235.499 428.001 240.499 434.251 C 245.499 440.501 249.749 459.251 253.749 466.751 C 258.217 472.258 295.122 477.39 337.495 478.236 Z"></path>
    <path id="ana_male_back_center" class="male-back-back" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 374.981 224.194 C 365.564 224.066 356.004 223.994 346.655 223.988 L 337.495 478.236 C 347.152 478.429 357.094 476.503 366.999 476.46 C 376.977 476.416 386.918 478.253 396.498 477.972 Z"></path>
    <path id="ana_male_back_right" class="male-back-back" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 396.498 477.972 C 436.077 476.809 469.478 471.627 473.694 466.502 C 476.307 460.794 480.108 451.865 481.167 448.335 C 482.667 443.335 483.833 439.168 486 435.668 C 488.167 432.168 493.833 417.585 494.666 414.585 C 495.499 411.585 496.833 405.168 498 405.085 C 497 389.667 498 370.667 501 353 C 503.836 336.3 507.264 316.031 505.097 304.29 C 504.972 303.61 504.831 302.954 504.667 302.334 C 501.667 291.001 497 250.334 459.833 228.001 C 451.541 226.252 414.523 224.731 374.981 224.194 Z"></path>
    <path id="ana_male_loin_left" class="male-back-loin" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 337.496 478.234 C 295.123 477.388 258.219 472.256 253.75 466.749 C 257.75 474.249 260.25 485.749 262.25 504.499 C 264.25 523.249 260 536.499 259 542.249 C 258 547.999 258.773 566.129 260.25 570.249 C 261.662 574.188 263.857 579.29 259.828 586.061 C 266.106 576.881 290.384 569.404 316.471 569.404 C 328.458 569.404 335.826 571.098 341.721 573.11 Z"></path>
    <path id="ana_male_loin_center" class="male-back-loin" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 341.721 573.11 C 350.349 576.055 355.822 579.683 368.001 579.683 C 379.77 579.683 386.935 575.83 396.75 572.868 L 396.499 477.97 C 386.919 478.251 376.978 476.414 367 476.458 C 357.094 476.501 347.153 478.427 337.496 478.234 Z"></path>
    <path id="ana_male_loin_right" class="male-back-loin" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 396.75 572.868 C 402.781 571.048 409.812 569.564 419.527 569.517 C 444.766 569.394 463.38 576.707 467.906 586.11 C 467.374 584.831 466.991 583.94 466.834 583.5 C 466 581.167 465.668 577.333 466.501 575.333 C 467.334 573.333 469.168 562.5 469.334 556.333 C 469.5 550.166 465.667 526.333 464.667 521.5 C 463.667 516.667 466.334 493 467.001 488.167 C 467.668 483.334 470.001 473.5 471.334 471.334 C 471.726 470.697 472.607 468.878 473.695 466.501 C 469.479 471.625 436.078 476.807 396.499 477.97 Z"></path>
    <path id="ana_male_buttock_left" class="male-back-buttocks" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 368 579.684 C 347.5 579.684 346 569.405 316.47 569.405 C 290.383 569.405 266.105 576.882 259.827 586.062 C 259.642 586.373 259.461 586.682 259.249 587 C 253.249 596 247.249 638.75 247.749 651 C 248.249 663.25 245.249 675 243.749 683.5 C 243.749 702.5 251.073 708.563 259.065 720.642 C 279.76 751.914 317.235 774.904 351.5 741 C 354.25 738.125 358.25 732.125 359.25 729.375 C 360.25 726.625 361.25 726.125 363.625 726.125 Z"></path>
    <path id="ana_male_buttock_right" class="male-back-buttocks" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 363.625 726.125 C 366 726.125 367.375 727.25 367.875 729 C 368.375 730.75 371.667 737.5 375.167 740.334 C 412.941 780.237 450.045 753.294 469.581 721.93 C 478.114 708.229 483.715 707 483.715 683.5 C 482.157 675.063 480.326 665.413 479.667 661.833 C 478.5 655.5 479.167 637.5 477.001 619.166 C 475.379 605.434 470.95 593.572 468.48 587.502 C 468.274 586.997 468.082 586.532 467.906 586.109 C 463.379 576.708 444.765 569.395 419.526 569.518 C 394 569.642 387 579.684 368 579.684 Z"></path>
    <path id="ana_male_shoulder_right_back" class="male-back-shoulder-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M504.667,302.333c0.164,0.62,0.305,1.276,0.43,1.956c16.05,17.076,38.941,31.042,53.413,43.878c-0.086-0.138-0.174-0.282-0.26-0.417C558.5,327.5,563,297,546,269.25S487.833,237.5,459.833,228C497,250.333,501.667,291,504.667,302.333z"/>
    <path id="ana_male_shoulder_left_back" class="male-back-shoulder-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M222.5,302c1-5.75,6.083-52.999,45.542-74.5c-8.126,3.074-15.006,5.307-18.542,6.25c-8.263,2.203-41.894,9.408-53.5,19.5c-17.25,15-26,35-27.5,62.75c-0.721,13.331,0,25.833,0,34.5C178.333,326.25,202.667,324.333,222.5,302z"/>
    <path id="ana_male_arm_right_back" class="male-back-arm-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M560.107,468.68c14.466-5.319,29.127-11.117,27.667-40.179c-2.005-7.583-4.833-13.009-8.024-28.751c-3.706-18.282-14.001-39.975-21.24-51.583c-14.472-12.836-37.362-26.802-53.413-43.878c2.167,11.742-1.261,32.011-4.097,48.71c-3,17.667-4,36.667-2.999,52.083C499.168,405,500.75,408,502,410.75c1.086,2.39,15.767,34.99,21.069,46.274C532.186,470.092,544.849,474.29,560.107,468.68z"/>
    <path id="ana_male_arm_left_back" class="male-back-arm-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M224.75,338.75c-1.75-10.5-3.25-31-2.25-36.75c-19.833,22.333-44.167,24.25-54,48.5c-6.833,10.667-18.25,33.75-20,44s-4.5,20-7.25,27.75c-3.98,34.526,10.693,40.75,26.143,46.43c16.538,6.08,29.232,0.639,38.288-15.131c1.1-2.171,2.2-4.311,3.152-6.215c3.5-7,16.417-34.458,17.292-37.333s2.125-4.875,3.25-4.875C233,368.333,226.5,349.25,224.75,338.75z"/>
    <path id="ana_male_elbow_right_back" class="male-back-elbow-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M568.824,504.292c39.135-10.364,46.681,1.813,50.268,8.767c-0.214-1.377-0.413-2.655-0.592-3.809c-2.75-17.75-17.75-47-19.5-49.75s-8.25-16.5-10.25-26.75c-0.298-1.528-0.625-2.92-0.976-4.249c1.46,29.062-13.201,34.86-27.667,40.179c-15.259,5.61-27.921,1.412-37.038-11.656c0.798,1.699,1.386,2.92,1.681,3.476c2.25,4.25,2.25,4.75,2.177,7.75s2.823,14.25,4.073,19.5c1.179,4.95,0.139,15.905,7.558,38.93C541.114,514.447,559.768,506.69,568.824,504.292z"/>
    <path id="ana_male_elbow_left_back" class="male-back-elbow-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M167.393,468.68c-15.45-5.68-30.124-11.904-26.143-46.43c-2.75,7.75-1.75,15.25-6.5,23.5s-0.75,6.5-9.75,20c-4.221,6.332-8.992,20.141-13.178,35.472c-1.258,4.606-2.463,9.351-3.584,14.07c3.399-5.935,6.601-22.609,50.438-11c10.714,2.837,31.865,11.173,26.897,27.549c2.671-7.794,4.745-15.229,6.308-21.617c2.547-10.41,3.739-18.036,3.953-19.891c0.5-4.333,0.833-7.333,1.5-9.333s2.167-9.833,2.333-13.167c0.122-2.427,3.069-8.474,6.014-14.285C196.625,469.319,183.931,474.76,167.393,468.68z"/>
    <path id="ana_male_forearm_right_back" class="male-back-forearm-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M620.191,621.035c8.857-3.745,15.074-6.784,16.994-10.783c-1.959-5.819-4.01-12.795-5.436-20.252c-3.039-15.895-9.573-57.137-12.658-76.941c-3.587-6.955-11.133-19.131-50.268-8.767c-9.056,2.398-27.71,10.155-30.267,22.388c0.45,1.397,0.928,2.833,1.442,4.32c9,26,30,55.25,45.5,79.5c2.965,4.638,5.481,8.858,7.625,12.689C599.133,625.104,611.447,624.732,620.191,621.035z"/>
    <path id="ana_male_forearm_left_back" class="male-back-forearm-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M158.676,504.292c-43.837-11.609-47.038,5.065-50.438,11c-3.104,13.064-5.568,25.943-6.738,35.208c-2.207,17.467-8.379,42.596-11.756,56.062c-0.875,6.021,6.182,9.66,17.564,14.473c11.004,4.653,23.67,4.044,26.295,0.232c10.117-17.065,26.772-37.525,39.896-61.517c4.95-9.049,8.926-18.728,12.073-27.909C190.541,515.465,169.39,507.129,158.676,504.292z"/>
    <path id="ana_male_wrist_right_back" class="male-back-wrist-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M648,637.75c-3.5-0.5-4-8.25-5.25-12.25c-0.702-2.246-3.058-7.8-5.564-15.248c-1.92,3.999-8.137,7.038-16.994,10.783c-8.745,3.698-21.058,4.07-27.066,2.155c9.067,16.197,11.432,25.37,12.375,29.144c0.527,2.109,0.643,3.571,0.461,4.91c8.146-4.652,34.231-16.276,43.574-19.125C648.977,637.944,648.459,637.815,648,637.75z"/>
    <path id="ana_male_wrist_left_back" class="male-back-wrist-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M107.309,621.035c-11.382-4.813-18.439-8.452-17.564-14.473c-1.215,4.844-2.068,8.179-2.244,9.105c-0.667,3.5-4.164,10.214-6.167,18.333c-0.375,1.692-2.811,3.547-5.5,4.5C79.5,637.75,120.411,656.865,121,659c-1-4-1.25-8,7-27c1.483-3.416,3.387-6.993,5.604-10.733C130.979,625.079,118.313,625.688,107.309,621.035z"/>
    <path id="ana_male_hand_right_back" class="male-back-hand-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M675.668,654.333c-2.333-1-7.918-8.083-12.668-10.083c-4.127-1.738-9.761-4.982-13.464-6.132c-9.342,2.85-35.428,14.474-43.574,19.125c-0.221,1.623-0.881,3.065-1.795,5.257c-1.667,4-0.666,16.167,0.334,19.5c1,3.334,4.166,22.334,5.833,26s3,8.167,3.667,10.5s7.667,32,10.167,34.333s5.666,1.834,7-0.5s0.5-7.5,0-10.833s-1.667-9.833-2-12.5s-2.334-10.5-3.334-14.166c-1-3.667,1.334-3.668,3-1.5c1.666,2.166,3.334,8.666,4.167,11.833s3.5,16.166,4.333,20.666s2.834,17.667,5.834,20.834s5.666,3.333,8.166,1s1.167-7.333,0.834-10.167s-2.5-19.166-2.834-23s-3.833-14.334-4.666-20.5s2.666-1.834,3-0.5s4.166,14.833,4.666,18.333s3,15.667,3.5,22.667s3.667,13,4.834,14.5s6,2.167,7.5,0s1.166-5.667,1-9.333s-1.5-22.167-1.5-25.667s-4.5-19.834-5-23.5s1.333-1.834,2-0.166c0.667,1.666,4.999,19.166,5.833,22.833s1.166,7.333,1.833,12s3.833,9,6.333,10.333s5.5-1.166,6.5-3.833s-1.5-15.167-1.833-23.333s-1.5-11.334-2.167-14c-0.667-2.667-3-18.167-3.833-22.5c-0.833-4.334-6.666-19-7.666-21.834s4.166,0.5,5.666,2.833s7.5,5.5,10.5,5.333s5.667-1.667,6-5.333s-3.833-4.5-5.833-9.833C684.556,659.146,678.001,655.333,675.668,654.333z"/>
    <path id="ana_male_hand_left_back" class="male-back-hand-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M75.833,638.5c-3.168,1.123-14.167,7-17.833,8.5s-5.833,6.5-10.167,9s-8.333,6-9,8.833s-5.5,4.333-5.5,7.333s2,5.333,6.879,6s12.621-8,14.121-9.5s2.5,0.5,1.833,2.333s-5.667,15-6.833,19.834c-1.167,4.833-3.833,17-4.5,21s-3,20.999-3.333,23.999s-3.333,15,1.167,18.334s7.833-2.334,9.833-7.667s1.5-11.833,2.667-14.5s4.333-19,6.333-22.5s2.833,1.166,1.667,4.166S59.333,729.833,59.333,732s-1.833,14-2.5,18s-1.333,14,0,18.167s7.167,1.666,9-0.5s3.667-11.167,4.5-16.5s1-14.167,2.531-20S76.5,714.834,78.333,712s3.833,0.334,3.333,2.5s-2.333,9.5-4,16.333s-1.5,14.5-3,21.334S71.5,764.5,74.667,768s6.5,0.833,8.5-1.667S87.5,753,88.833,744.5s4.667-21.166,5.833-25.166c1.167-4.001,3.5-7.834,5.333-7.5c1.833,0.333-0.167,6-1,9.166s-5,20.667-5.167,26.334s2.5,7.833,5.667,6.5s4.333-6,5-8.834s2.667-8.666,3.167-12s4.167-16.166,6.167-20.334c2-4.166,2.833-9.332,6.673-27.332C124.346,667.333,122,663,121,659C120.411,656.865,79.5,637.75,75.833,638.5z"/>
    <path id="ana_male_hamstring_right" class="male-back-hamstring-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M375.167,740.334C376,746.168,375.25,750.5,374,769s3.25,73.25,6.5,86.75s7,38,8.75,56.25c1.093,11.397,3.356,23.087,5.571,32.39c8.43,9.247,24.089,12.271,39.666,11.319c15.283-0.934,32.867-4.996,46.759-24.891c0.889-5.953,1.705-9.622,2.004-10.818c0.75-3,10.75-28,13.5-41.25s4.25-43.083,5.25-58.083s-4.499-54.001-5.833-61.667s-3.833-29.666-5.166-35.833s-4.334-21.667-4.834-25.667c-0.218-1.739-1.254-7.511-2.452-14c0,23.5-5.601,24.729-14.134,38.43C450.045,753.294,412.941,780.237,375.167,740.334z"/>
    <path id="ana_male_hamstring_left" class="male-back-hamstring-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M259.066,720.642c-7.993-12.078-15.316-18.142-15.316-37.142c-1.5,8.5-8.25,43-9.75,54s-3,14.5-7.25,46.75s-1.25,76,2.75,93.5S242.25,914,244.75,927c14.239,23.213,32.047,27.719,48.263,28.709c17.666,1.079,33.441-2.949,40.654-15.376c1.667-5.833,6-44.5,8.167-58s9.5-61.333,10.5-78.667s1-34.999,0.167-40.999s-1.625-16.292-1-21.667C317.235,774.904,279.76,751.914,259.066,720.642z"/>
    <path id="ana_male_knee_right_back" class="male-back-knee-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M434.486,955.709c-15.577,0.951-31.235-2.072-39.666-11.319c1.332,5.594,2.646,10.325,3.679,13.61c2.75,8.75,2.25,34.25,5.5,40.5c2.566,4.935,3.723,9.253,3.484,15.155c6.028-4.677,22.368-6.785,36.539-8.198c13.658-1.361,29.354,1.297,35.854,14.047c-1.023-13.899-1.763-29.888-1.628-46.754c0.15-18.787,1.656-32.959,2.996-41.932C467.354,950.713,449.77,954.775,434.486,955.709z"/>
    <path id="ana_male_knee_left_back" class="male-back-knee-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M293.013,955.709c-16.216-0.99-34.024-5.496-48.263-28.709c2.5,13,3.25,32.25,4.25,53.5c0.655,13.917-0.084,29.658-1.164,42.445c2.574-20.91,19.106-19.136,35.64-17.488c16.633,1.658,33.267,3.272,35.69,9.876c-1.167-5.5,0.667-11.167,3-16s3.167-17.833,4-28.833s5.833-24.334,7.5-30.167C326.455,952.76,310.679,956.788,293.013,955.709z"/>
    <path id="ana_male_calf_right" class="male-back-calf-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M444.023,1005.457c-14.171,1.413-30.511,3.521-36.539,8.198c-0.064,1.573-0.221,3.253-0.484,5.095c-1.25,8.75-7,65.25-7.5,84.75s7.5,36,10.5,40s3.75,15.5,4,21.75c0.127,3.173,1.801,16.722,3.811,30.928c5.639,7.736,15.869,11.903,25.566,11.521c11.76-0.464,25.932-3.604,30.46-12.624c0.124-3.28,0.257-6.378,0.413-9.074c0.75-13,4.75-46.75,7.5-74s3-44.75,1-62.25c-0.92-8.055-2-18.392-2.872-30.246C473.377,1006.754,457.682,1004.096,444.023,1005.457z"/>
    <path id="ana_male_calf_left" class="male-back-calf-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M283.476,1005.457c-16.533-1.647-33.065-3.422-35.64,17.488c-0.569,6.737-1.232,12.655-1.836,17.055c-1.75,12.75-5,46-2.5,60s8.75,70.5,9,91.75c2.411,11.598,18.52,15.432,31.624,15.948c13.165,0.52,23.325-2.338,25.624-16.146c1.52-12.183,2.896-25.104,3.086-31.552c0.333-11.333,8.333-24,12.833-37.334s0.5-46.666-1.167-65.5s-4.167-36.333-5.333-41.833C316.743,1008.729,300.109,1007.115,283.476,1005.457z"/>
    <path id="ana_male_ankle_right_back" class="male-back-ankle-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M443.377,1207.698c-9.697,0.383-19.927-3.784-25.566-11.521c1.949,13.775,4.213,28.17,5.69,34.323c3,12.5,1,17.833-1.833,26.667s-2.334,14.333-1.334,21.999s0.667,17.5,0.5,24.5c-0.097,4.075-2.111,11.312-2.63,18.029c5.397-8.651,37.767-2.677,48.526,9.038c0.54-0.488,1.031-0.948,1.458-1.357c0.771-4.053,1.114-8.488,0.792-12.721c-0.999-13.15-1.991-21.145,2.987-33.769c-0.096-0.073-0.193-0.146-0.301-0.221C469.5,1281.167,470,1270,470,1261s1.25-24.25,2.25-32c0.792-6.143,1.114-21.391,1.587-33.926C469.309,1204.095,455.137,1207.234,443.377,1207.698z"/>
    <path id="ana_male_ankle_left_back" class="male-back-ankle-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M284.124,1207.698c-13.104-0.517-29.212-4.351-31.624-15.948c0.25,21.25,4.125,51.5,4.25,58.125s-1.25,26.75-1,28.625s0.25,3.75-0.875,3.75c6.082,14.415,4.342,25.212,3.644,34.406c-0.388,5.104,0.181,9.513,1.315,14.177c10.5-13.499,47.957-20.15,48.229-7.491c-0.177-6.154-1.244-13.505-2.062-20.509c-1.5-12.834,1.833-27.333,2.167-31.167s-4.5-18.5-5.833-25.5s2.167-19.166,4.167-31.333c0.862-5.245,2.096-14.051,3.247-23.281C307.448,1205.36,297.289,1208.218,284.124,1207.698z"/>
    <path id="ana_male_heel_right" class="male-back-sole-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M418.204,1321.696c-0.372,4.823,0.025,9.38,2.463,12.305c6.573,7.889,13.334,10.333,26.667,7.166c9.396-2.231,15.716-7.104,19.396-10.433C455.971,1319.02,423.602,1313.045,418.204,1321.696z"/>
    <path id="ana_male_heel_left" class="male-back-sole-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M259.833,1330.833C262.167,1333,272.5,1340,283,1342.512s19.167-1.512,23-7.179c1.741-2.574,2.21-6.868,2.062-11.991C307.791,1310.683,270.333,1317.334,259.833,1330.833z"/>
    <path id="ana_male_foot_right_back" class="male-back-foot-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M468.981,1316.656c0.321,4.232-0.021,8.668-0.792,12.721c0.792-0.758,1.396-1.358,1.812-1.71c2.167-1.834,16-5.666,21.5-7.5s7.333-7.166,7.666-10.166s0.5-2.667,1.834-5.834s-5.167-7.5-6-7.5s-2,0-2-1.5s-3.667-4.333-5.167-4.333s-3-1-3.5-2.5s-6.667-3.833-8.833-3.5c-2.058,0.316-1.715-0.571-3.533-1.946C466.991,1295.512,467.982,1303.506,468.981,1316.656z"/>
    <path id="ana_male_foot_left_back" class="male-back-foot-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M258.519,1316.656c0.699-9.194,2.438-19.991-3.644-34.406c-1.125,0-2.875,1.375-3.125,2.625s-2.375,0.625-4,0.125s-6.625,4.5-6.75,5.125s-0.25,1.25-2.25,1.125s-5.75,3.125-5.875,4.125s-1.208,1.792-2.875,1.958s-4.167,3-5.167,5.334s0.833,4.833,1.667,9.166s6.667,9.333,18.833,12.167s12.167,4.666,14.5,6.833C258.699,1326.169,258.13,1321.761,258.519,1316.656z"/>
    <g v-if="!sideBySide">
      <image overflow="visible" x="597" y="1026" width="149" height="274" href="/assets/img/rtm/male-front.png"></image>
      <rect x="597" y="1026" fill="transparent" stroke="#6a6a6a" vector-effect="non-scaling-stroke" width="149" height="274"/>
      <rect class="goa" x="597" y="1026" fill="#72B8FC" stroke="#6a6a6a" vector-effect="non-scaling-stroke" width="149" height="274"/>
    </g>
    <g id="anaspotsb"></g>
  </svg>
</template>

<script>
export default {
  name: 'MaleBack',
  props: {
    sideBySide: Boolean,
  },
}
</script>
