import { anatomyConfig } from './anatomy'

const anaaddEvent = (id, onClick) => {
  var _obj = document.getElementById(id)
  _obj.setAttribute("cursor",  "default")
  if (anatomyConfig[id].enabled === true) {
    if (anatomyConfig[id].target !== "none") {
      _obj.addEventListener("mousedown", () => {
        onClick(id.split('_').slice(2).join('_'))
      })
    }
  }
}
const addInteractions = (sideBySide, onClick) => {
  document.querySelectorAll("path[id^=ana]").forEach((e) => {
    anaaddEvent(e.id, onClick)
  })
  if (sideBySide) {return}
  document.getElementById("baseb").style.display = 'none'
  document.querySelector(".gob").addEventListener("click", function(){
    document.getElementById("basea").style.display = 'none'
    document.getElementById("baseb").style.display = 'block'
  })
  document.querySelector(".goa").addEventListener("click", function(){
    document.getElementById("baseb").style.display = 'none'
    document.getElementById("basea").style.display = 'block'
  })
}

export default addInteractions
