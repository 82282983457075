<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>
          Pain Management
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-loading :is-open="loading" message="Loading..." :duration="20000" cssClass="medium-loading-container"
        @didDismiss="loading=false"/>
      <ion-toast position="top" color="warning" :is-open="warning" :message="warningMessage" :duration="5000"
        @didDismiss="warning=false"/>
      <ion-toast position="top" color="success" :is-open="success" :message="successMessage" :duration="5000"
        @didDismiss="success=false"/>
      <Body ref="bodyFigure" :female="isFemale" sideBySide @locationClick="onBodyClick" @spotClick="onBodyClick"
        class="anatomy-container" :class="{'is-male': !isFemale}"/>
      <div v-if="!loading">
        <div v-if="painLocationsAvailable">
          <ion-button expand="block" class="survey-button" @click="startFormsToday"
              :color="completedAllFormsToday ? 'success' : 'primary'">
            {{completedAllFormsToday ? "All done for today!" : "Start today's surveys"}}
          </ion-button>
          <div v-if="vitalsAvailable">
            <div v-for="painLocation in patientPainLocations.data" :key="painLocation.id">
              <VitalList v-if="clinicalIDAvailable(painLocation.location)"
                :vitalType="getVitalType(painLocation.location)" rtm/>
            </div>
          </div>
          <div v-else>
            <ion-card>
              <ion-card-content class="ion-text-start">
                Tap the button above to complete your first daily pain survey.
              </ion-card-content>
            </ion-card>
          </div>
        </div>
        <ion-card v-else>
          <ion-card-content class="ion-text-start">
            You have no pain location on file yet. Please
            <a style="text-decoration:none" @click="$router.push('/messages')">
              contact your care manager
            </a>
            for more information.
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
    <RTMVitalFormModal :showModal="showRTMVitalFormModal" :location="painLocationSelected" @dismissModal="dismissModal"
      ref="rpmVitalFormModal"/>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonLoading,
  IonCard,
  IonCardContent,
  IonToast,
  IonButton,
} from '@ionic/vue'
import { mapGetters } from 'vuex'
import { dismissElement } from '@/utils/overlay'
import { anatomyConfig, spotBaseConfig, spotDanger, spotSuccess } from '@/utils/rtm/anatomy'
import { humanize } from '@/shared/utils'
import { PATIENT_PAIN_LOCATIONS_CHECK, PATIENT_RTM_VITALS_CHECK, PATIENT_RTM_VITALS_REQUEST } from '@/store/actions/patient'
import RTMVitalFormModal from '@/components/RTMVitalFormModal'
import VitalList from '@/components/VitalList'
import Body from '@/components/Anatomy/Body'
import dayjs from 'dayjs'

export default {
  name: 'Pain',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonLoading,
    IonCard,
    IonCardContent,
    IonToast,
    IonButton,
    Body,
    VitalList,
    RTMVitalFormModal,
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'patientRTMVitals',
      'patientPainLocations',
    ]),
    painLocationsAvailable () {
      return !this.loading && this.patientPainLocations.hasLoadedOnce && this.patientPainLocations.data.length
    },
    vitalsAvailable () {
      let vitals = this.patientRTMVitals
      return !this.loading && vitals.hasLoadedOnce && Object.values(vitals.data).some(({data}) => data.length)
    },
    isFemale () {
      return ((this.userProfile || {}).role_data || {}).gender === 'female'
    },
    completedAllFormsToday () {
      return this.patientPainLocations.data.every(({location}) => this.hasVitalToday(location))
    },
    nextFormToday () {
      return this.patientPainLocations.data.find(({location}) => !this.hasVitalToday(location))
    },
  },
  data () {
    return {
      loading: false,
      painLocationSelected: null,
      showRTMVitalFormModal: false,
      warning: false,
      warningMessage: "Form submitted successfully. Continue with other pain locations.",
      success: false,
      successMessage: "All done for today! Don't forget to come back tomorrow.",
      completingSurveys: false,
    }
  },
  methods : {
    startFormsToday () {
      if (this.completedAllFormsToday) {return}
      this.completingSurveys = true
      this.painLocationSelected = this.nextFormToday.location
      this.showRTMVitalFormModal = true
    },
    dismissModal (showToast) {
      this.loading = true
      let continueToNextSurvey = showToast && this.completingSurveys
      if (!continueToNextSurvey) {
        this.showRTMVitalFormModal = false
      }
      this.$store.dispatch(PATIENT_RTM_VITALS_REQUEST, {params: {mobile: true}}).then(() => {
        this.loading = false
        this.reloadSpot(this.painLocationSelected)
        if (showToast) {
          if (this.completingSurveys) {
            if (this.nextFormToday) {
              this.painLocationSelected = this.nextFormToday.location
              this.$nextTick(this.$refs.rpmVitalFormModal.setupModal)
              return
            } else {
              this.showRTMVitalFormModal = false
              this.completingSurveys = false
            }
          }
          if (this.completedAllFormsToday) {
            this.success = true
          } else {
            this.warning = true
          }
        } else if (this.completingSurveys) {
          this.completingSurveys = false
        }
      })
    },
    getLocation (location) {
      return this.patientPainLocations.data.find((painLocation) => {return painLocation.location === location})
    },
    painLocationAvailable (location) {
      return this.painLocationsAvailable && this.getLocation(location)
    },
    clinicalIDAvailable (clinicalID) {
      return this.vitalsAvailable && this.patientRTMVitals.data[clinicalID].data.length
    },
    onBodyClick (location) {
      if (!this.painLocationAvailable(location)) {return}
      this.painLocationSelected = location
      this.showRTMVitalFormModal = true
    },
    hasVitalToday (location) {
      if (!this.clinicalIDAvailable(location)) {
        return false
      }
      let vitals = this.patientRTMVitals.data[location].data
      let lastVital = vitals[vitals.length - 1]
      let now = dayjs()
      let vitalDate = dayjs(lastVital.date)
      return now.isSame(vitalDate, 'day')
    },
    getSpots (painLocations, front) {
      let spotName, spot, spotBoundingBox, spotConfig
      let xTranslation = this.isFemale ? (-771) : 0
      let data = painLocations ? painLocations : this.patientPainLocations.data
      return data.reduce((spots, painLocation) => {
        spotName = `ana_${this.userProfile.role_data.gender}_${painLocation.location}`
        spot = document.getElementById(spotName)
        if (spotName in anatomyConfig && front === anatomyConfig[spotName].front && spot) {
          spotBoundingBox = spot.getBBox()
          spotConfig = {
            ...spotBaseConfig,
            ...(this.hasVitalToday(painLocation.location) ? spotSuccess : spotDanger),
            'location': painLocation.location,
            'pos_X': spotBoundingBox.x + spotBoundingBox.width / 2 + xTranslation,
            'pos_Y': spotBoundingBox.y + spotBoundingBox.height / 2,
          }
          spots.push(spotConfig)
        }
        return spots
      }, [])
    },
    addSpotsToBody (painLocation) {
      let painLocations = painLocation ? [painLocation] : null
      let spotsFront = this.getSpots(painLocations, true)
      let spotsBack = this.getSpots(painLocations)
      this.$refs.bodyFigure.addSpotsToBody(spotsFront, spotsBack)
    },
    reloadSpot (painLocation) {
      this.$refs.bodyFigure.removeSpotFromBody(painLocation)
      this.addSpotsToBody({location: painLocation})
    },
    getLocationName (location) {
      return location ? humanize(location.split('_').reverse().join(' ')) : ''
    },
    getDate (date) {
      return dayjs(date).format('MMM D, YYYY')
    },
    getVitalType (location) {
      return {
        path: location,
        label: this.getLocationName(location),
        clinicalIDs: [location],
      }
    },
  },
  ionViewDidEnter () {
    if (this.painLocationsAvailable && this.vitalsAvailable) {
      this.patientPainLocations.data.forEach(({location}) => {
        this.reloadSpot(location)
      })
      return
    }
    dismissElement('ion-loading')
    this.loading = true
    Promise.all([
      this.$store.dispatch(PATIENT_PAIN_LOCATIONS_CHECK),
      this.$store.dispatch(PATIENT_RTM_VITALS_CHECK, {params: {mobile: true}}),
    ]).then(() => {
      this.loading = false
      this.$refs.bodyFigure.addInteractionsToBody()
      this.addSpotsToBody()
    })
  },
}
</script>

<style scoped>
ion-item {
  --border-style: none;
}
ion-card-content {
  text-align: center;
}
.anatomy-container {
  max-width: 500px;
  margin: -10px auto -5px auto;
}
.anatomy-container.is-male {
  margin-top: 10px;
  margin-bottom: 5px;
}
.survey-button {
  margin: 10px 25px;
}
</style>
