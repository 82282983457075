const addSpotEvents = (side, index, id, onClick) => {
  let { config } = side
  var obj = document.getElementById(`anaspots_${id}`)
  if(config.pins[index].enabled === true){
    obj.style.cursor = "pointer"
    obj.addEventListener("mousedown", () => {
      onClick(config.pins[index].location)
    })
  }
}
const addSpotsToSide = (side, onClick) => {
  let {config, name} = side
  var pins_len = config.pins.length
  if(pins_len > 0) {
    var xmlns = "http://www.w3.org/2000/svg"
    var tsvg_obj = document.getElementById(`anaspots${name}`)
    var svg_circle
    for (var i = 0; i < pins_len; i++) {
      svg_circle = document.createElementNS(xmlns, "circle")
      svg_circle.setAttributeNS(null, "cx", config.pins[i].pos_X)
      svg_circle.setAttributeNS(null, "cy", config.pins[i].pos_Y)
      svg_circle.setAttributeNS(null, "r", config.pins[i].size / 2)
      svg_circle.setAttributeNS(null, "fill", config.pins[i].upColor)
      svg_circle.setAttributeNS(null, "stroke", config.pins[i].outline)
      svg_circle.setAttributeNS(null, "stroke-width", 5)
      svg_circle.setAttributeNS(null, "id", `anaspots_${config.pins[i].location}`)
      tsvg_obj.appendChild(svg_circle)
      addSpotEvents(side, i, config.pins[i].location, onClick)
    }
  }
}
const addSpots = (spotsFront, spotsBack, onClick) => {
  const frontAndBack = [
    {
      config: {"pins": spotsFront},
      name: "a",
    },
    {
      config: {"pins": spotsBack},
      name: "b",
    },
  ]
  frontAndBack.forEach((side) => {
    addSpotsToSide(side, onClick)
  })
}
const removeSpot = (id) => {
  let spot = document.getElementById(id)
  if (spot.parentNode) {
    spot.parentNode.removeChild(spot)
  }
}

export {
  addSpots,
  removeSpot,
}
