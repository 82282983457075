<template>
  <ion-card class="monitor-vital-card" button @click="goToVital">
    <ion-card-header>
      <ion-card-subtitle :color="vitalColor">
        <div class="monitor-vital-name">
          <ion-icon :ios="vitalIcon" :md="vitalIcon" :color="vitalColor" class="vital-icon"/>
          {{ vitalType.label }}
        </div>
        <div class="monitor-vital-date">
          {{ lastVitalDate() }}
          <ion-icon :ios="chevronForward" :md="chevronForward"/>
        </div>
      </ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <span class="monitor-vital-value">
        {{ lastVitalValue() }}
      </span>
      <span class="monitor-vital-unit">
        {{ lastVitalUnit() }}
      </span>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonIcon,
} from '@ionic/vue'
import { defineComponent } from 'vue'
import {
  accessibility,
  heart,
  fitness,
  chevronForward,
} from 'ionicons/icons'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'VitalList',
  props: {
    vitalType: Object,
    rtm: Boolean,
  },
  components: {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonIcon,
  },
  setup () {
    return {
      chevronForward,
    }
  },
  computed: {
    ...mapGetters([
      'patientRPMVitals',
      'patientRTMVitals',
    ]),
    patientVitals () {
      return this.rtm ? this.patientRTMVitals : this.patientRPMVitals
    },
    vitalIcon () {
      switch (this.vitalType.value) {
        case 'weight':
          return accessibility
        case 'bloodGlucose':
          return fitness
        default:
          return heart
      }
    },
    vitalColor () {
      switch (this.vitalType.value) {
        case 'weight':
          return 'tertiary'
        default:
          return 'danger'
      }
    },
  },
  methods: {
    goToVital () {
      this.$router.push(`/vitals/${this.vitalType.path}`)
    },
    lastVital (clinicalID) {
      let clinicalIDVitals
      let defaultClinicalID = this.vitalType.value === 'bloodPressure' ? clinicalID || 'systolic' : null
      if (defaultClinicalID) {
        clinicalIDVitals = this.patientVitals.data[defaultClinicalID].data
        return clinicalIDVitals[clinicalIDVitals.length - 1]
      }
      let vitals = this.vitalType.clinicalIDs.map(clinicalID => {
        clinicalIDVitals = this.patientVitals.data[clinicalID].data
        return clinicalIDVitals[clinicalIDVitals.length - 1]
      }).filter(vital => vital)
      if (vitals.length < 2) {
        return vitals[0]
      }
      return vitals.reduce((a, b) => new Date(a.date) > new Date(b.date) ? a : b)
    },
    lastVitalDate () {
      let lastVital = this.lastVital()
      if (!lastVital) {return}
      let date = lastVital.date
      let containsTime = date.includes('T')
      let vitalDate = containsTime ? date.slice(0, -6) : date  // remove TZ info to prevent converting to local TZ
      let parsedDate = dayjs(vitalDate)
      let now = dayjs()
      let yesterday = now.subtract(1, 'days')
      if (yesterday.isSame(parsedDate, 'day')) {
        return 'Yesterday'
      }
      let vitalDateFormat = 'MMM D, YYYY'
      if (now.isSame(parsedDate, 'day')) {
        vitalDateFormat = 'h A'
      } else if (now.isSame(parsedDate, 'year')) {
        vitalDateFormat = 'MMM D'
      }
      return parsedDate.format(vitalDateFormat)
    },
    lastVitalValue () {
      let lastVital = this.lastVital()
      if (!lastVital) {return}
      let value = lastVital.float_value.toFixed(0)
      if (this.vitalType.value === 'bloodPressure') {
        let diastolicValue = this.lastVital('diastolic').float_value.toFixed(0)
        return `${value}/${diastolicValue}`
      }
      return value
    },
    lastVitalUnit () {
      let lastVital = this.lastVital()
      if (!lastVital) {return 'Not Available'}
      if (this.rtm) {
        return lastVital.value_description
      }
      let unit = lastVital.new_unit
      return this.vitalType.value === 'heartRate' ? unit.toUpperCase() : unit
    },
  },
})
</script>

<style scoped>
.monitor-vital-card ion-card-header {
  padding: 15px 20px 10px 20px;
}
.monitor-vital-card ion-card-content {
  padding: 0 0 10px 20px;
}
.monitor-vital-date {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  text-transform: capitalize;
  color: var(--ion-color-medium-tint);
  font-weight: 500;
}
.monitor-vital-date ion-icon {
  margin-left: 0.5em;
}
.monitor-vital-name {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  font-size: 1.2em;
}
.monitor-vital-name ion-icon {
  margin-right: 0.1em;
}
.monitor-vital-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--ion-color-light-contrast);
}
.monitor-vital-unit {
  padding-left: 0.25em;
  font-weight: 600;
  font-size: 0.8em;
  color: var(--ion-color-medium-tint);
}
.vital-modal-title {
  text-transform: capitalize;
  font-size: 1rem;
}
</style>
