<template>
  <div>
    <span id="anatip"/>
    <ion-row id="anawrapper">
      <ion-col id="basea" class="animated fadeIn fadeOut">
        <FemaleFront v-if="female" :sideBySide="sideBySide"/>
        <MaleFront v-else :sideBySide="sideBySide"/>
      </ion-col>
      <ion-col id="baseb" class="animated fadeIn fadeOut">
        <FemaleBack v-if="female" :sideBySide="sideBySide"/>
        <MaleBack v-else :sideBySide="sideBySide"/>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import MaleFront from './MaleFront'
import MaleBack from './MaleBack'
import FemaleFront from './FemaleFront'
import FemaleBack from './FemaleBack'
import addInteractions from '@/utils/rtm/interactions'
import { addSpots, removeSpot } from '@/utils/rtm/spot_interactions'
import {
  IonRow,
  IonCol,
} from '@ionic/vue'

export default {
  name: 'Body',
  props: {
    female: Boolean,
    sideBySide: Boolean,
  },
  emits: ['locationClick', 'spotClick'],
  components: {
    MaleFront,
    MaleBack,
    FemaleFront,
    FemaleBack,
    IonRow,
    IonCol,
  },
  methods: {
    onLocationClick (location) {
      this.$emit('locationClick', location)
    },
    onSpotClick (spot) {
      this.$emit('spotClick', spot)
    },
    addSpotsToBody (spotsFront, spotsBack) {
      addSpots(spotsFront, spotsBack, this.onSpotClick)
    },
    removeSpotFromBody (location) {
      removeSpot(`anaspots_${location}`)
    },
    addInteractionsToBody () {
      addInteractions(this.sideBySide, this.onLocationClick)
    }
  },
}
</script>

<style scoped>
#anawrapper {
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	max-width: 1920px;
	margin: 0;
	padding: 0;
	background-color: transparent;
	min-width: 220px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
}
#basea, #baseb {
  padding: 0;
}
#anawrapper svg {
  height: 100%;
	width: 100%;
	margin: 0;
}
.gob, .goa{
	fill: #72B8FC;
	opacity: 0.05;
	-webkit-transition: fill 0.1s ease;
	-moz-transition: fill 0.1s ease;
	-o-transition: fill 0.1s ease;
	transition: fill 0.1s ease;
	cursor: pointer;
}
.gob:hover, .goa:hover{
	opacity: 0.4;
}
#anatip {
	font: 14px/16px Trebuchet MS, Helvetica, Arial, sans-serif;
	display: none;
	padding: 5px;
	max-width: 40%;
	border: 1px solid #404040;
	color: #404040;
	z-index: 1000;
	float: left;
	position: fixed;
	background: rgba(265, 265, 265, 0.9);
	word-break: keep-all;
	box-shadow:1px 2px 4px rgba(0, 0, 0, 0.5);
	-moz-box-shadow:1px 2px 4px rgba(0, 0, 0, 0.5);
	-webkit-box-shadow:1px 2px 4px rgba(0, 0, 0, 0.5);
}
#anatip p {
	margin: 0!important;
	color: #404040!important;
}
#anatip img {
	float: left;
	padding: 3px;
}
</style>
