<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="646px" height="1370px" viewBox="-13.5 0 646 1370" xml:space="preserve">
    <image overflow="visible" width="619" height="1370" href="/assets/img/rtm/female-back.png"></image>
    <g transform="translate(-771)">
      <path id="ana_female_head_back" class="female-back-head" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1126.355,199.416c0.778-1.884,1.421-3.543,1.734-4.601c8.91-30.064,10.593-68.02,7.453-84.763c-7.043-37.551-26.043-49.365-55.076-49.365c-24.545,0-45.633,15.813-51.082,50.793c-0.606,3.894-0.719,18.188-0.385,24.688s5.166,38.333,6.5,51.167c0.402,3.875,1.295,7.531,2.367,10.833C1052.742,213.064,1109.674,213.289,1126.355,199.416z"/>
      <path id="ana_female_neck_left_back" class="female-back-neck" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 1069.044 208.88 C 1055.55 207.461 1043.594 203.9 1037.868 198.166 C 1040.347 205.801 1043.803 211.521 1044.501 213.499 C 1045.501 216.332 1045.602 237.256 1043.668 240.832 C 1038.904 249.642 1023.218 254.904 1007.532 257.79 C 1017.741 259.419 1040.857 260.348 1066.229 260.551 Z"></path>
      <path id="ana_female_neck_center_back" class="female-back-neck" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 1066.229 260.551 C 1075.33 260.624 1084.72 260.604 1093.91 260.489 L 1093.456 209.172 C 1085.422 209.809 1076.968 209.714 1069.044 208.88 Z"></path>
      <path id="ana_female_neck_right_back" class="female-back-neck" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 1093.91 260.489 C 1122.731 260.128 1149.576 258.837 1159.289 256.58 C 1134.581 252.522 1115.391 250.472 1120.768 212.456 C 1120.843 211.927 1124.151 204.758 1126.356 199.415 C 1119.866 204.813 1107.284 208.076 1093.456 209.172 Z"></path>
      <path id="ana_female_back_left" class="female-back-back" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 1065.69 260.547 C 1040.53 260.335 1017.668 259.408 1007.531 257.791 C 1004.577 258.334 1001.626 258.791 998.745 259.174 C 977.839 273.906 970.207 300.33 970.106 312.137 C 970.054 318.213 970.823 323.604 971.847 328.336 C 973.497 335.959 975.816 341.86 976.47 346.081 C 977.405 352.102 980.569 358.586 979.817 376.391 C 980.512 375.629 981.178 376.317 981.416 378.166 C 981.75 380.749 981.83 386.812 982 392.333 C 982.834 419.5 990.25 454.75 992.5 466.75 C 993.364 471.357 994.634 479.36 995.912 488.457 C 1008.422 492.05 1032.896 494.909 1059.966 495.511 Z"></path>
      <path id="ana_female_back_center" class="female-back-back" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 1059.966 495.511 C 1068.319 495.697 1076.916 493.84 1085.486 493.798 C 1094.115 493.756 1102.714 495.527 1110.999 495.256 L 1094.142 260.486 C 1084.697 260.606 1075.035 260.626 1065.69 260.547 Z"></path>
      <path id="ana_female_back_right" class="female-back-back" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 1110.999 495.256 C 1135.938 494.439 1158.029 491.564 1169.603 488.152 C 1170.512 480.777 1171.362 474.393 1171.751 473 C 1173.099 468.178 1179.373 431.546 1180.251 427.75 C 1182.605 417.57 1182.753 400.644 1183.501 397.75 C 1184.25 394.857 1184.203 376.081 1185.251 376 C 1185.336 375.994 1185.55 376.349 1185.869 376.999 C 1185.572 369.208 1185.675 361.114 1187.059 352.366 C 1188.101 345.77 1191.622 337.253 1193.881 327.958 C 1195.565 321.032 1196.55 313.675 1195.283 306.354 C 1194.072 299.355 1186.7 273.266 1167.365 257.995 C 1164.626 257.47 1161.926 257.013 1159.289 256.58 C 1149.602 258.831 1122.875 260.121 1094.142 260.486 Z"></path>
      <path id="ana_female_loin_left" class="female-back-loin" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 1059.965 495.511 C 1032.896 494.909 1008.421 492.05 995.911 488.457 C 997.962 503.052 1000.037 520.469 1000.499 531.25 C 1001.249 548.75 997.499 557.25 991.749 570.5 C 989.415 575.877 985.228 586.032 980.643 598.005 C 993.222 591.332 1020.287 586.438 1039.581 586.438 C 1048.983 586.438 1055.221 587.684 1060.177 589.293 Z"></path>
      <path id="ana_female_loin_center" class="female-back-loin" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 1060.177 589.293 C 1069.199 592.221 1073.973 596.351 1085.854 596.351 C 1097.515 596.351 1104.142 591.827 1114.487 588.924 L 1110.997 495.256 C 1102.713 495.527 1094.114 493.755 1085.485 493.798 C 1076.916 493.84 1068.318 495.697 1059.965 495.511 Z"></path>
      <path id="ana_female_loin_right" class="female-back-loin" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 1114.487 588.924 C 1119.279 587.58 1124.867 586.584 1132.123 586.546 C 1151.383 586.446 1176.196 594.149 1186.491 602.237 C 1183.486 593.431 1180.614 584.57 1177.666 578.333 C 1173.333 569.166 1165.915 550.833 1164.999 546.75 C 1163.96 542.117 1165.899 518.911 1166.499 514.25 C 1166.853 511.497 1168.288 498.793 1169.601 488.152 C 1158.028 491.564 1135.937 494.439 1110.997 495.256 Z"></path>
      <path id="ana_female_buttock_left" class="female-back-buttocks" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 1085.855 596.351 C 1067.447 596.351 1066.099 586.438 1039.582 586.438 C 1020.287 586.438 993.223 591.333 980.644 598.005 C 973.931 615.533 966.363 636.958 962.5 653 C 956.769 676.808 955.509 699.636 955.291 716.701 C 984.449 755.951 1033.463 776.164 1072.245 740.701 C 1072.453 739.596 1072.966 739.029 1073.917 738.501 C 1075.417 737.667 1079.167 731.585 1080.167 729.751 C 1081.167 727.917 1079.667 725.501 1082.834 725.501 Z"></path>
      <path id="ana_female_buttock_right" class="female-back-buttocks" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M 1082.834 725.501 C 1086 725.501 1085 727.668 1085.917 729.918 C 1086.834 732.168 1091.167 736.751 1092.667 739.168 C 1092.911 739.562 1093.086 739.868 1093.217 740.125 C 1131.635 775.946 1180.306 756.63 1209.738 718.094 C 1209.183 699.414 1206.709 681.626 1204.5 668 C 1201.5 649.5 1195.5 626.333 1190.834 614.333 C 1189.343 610.498 1187.902 606.374 1186.492 602.237 C 1176.197 594.148 1151.384 586.445 1132.124 586.546 C 1109.202 586.666 1102.916 596.351 1085.855 596.351 Z"></path>
      <path id="ana_female_shoulder_right_back" class="female-back-shoulder-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1195.283,306.354c1.267,7.32,0.281,14.678-1.402,21.604c15.378,16.313,36.147,29.67,48.012,42.064c-2.872-15.51-2.674-29.3-12.393-66.522c-9.696-37.136-34.926-40.146-61.182-45.321c-0.318-0.063-0.636-0.124-0.953-0.184C1186.7,273.266,1194.072,299.356,1195.283,306.354z"/>
      <path id="ana_female_shoulder_left_back" class="female-back-shoulder-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M971.848,328.337c-1.024-4.732-1.793-10.124-1.741-16.199c0.101-11.807,7.732-38.231,28.639-52.963c-3.145,0.418-6.204,0.747-9.079,0.993c-19.5,1.667-29.999,7.167-40.666,17.667s-12.833,29-16.5,42.167s-6.166,32.833-8.666,47.667c-0.186,1.098-0.394,2.236-0.618,3.4C934.8,358.473,956.07,344.937,971.848,328.337z"/>
      <path id="ana_female_arm_right_back" class="female-back-arm-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1251.415,486.132c8.355-3.299,16.792-6.843,19.493-19.357c-2.969-9.552-5.826-22.777-10.408-36.525c-2.616-7.848-10.031-27.096-16.289-50.052c-0.987-3.621-1.719-6.936-2.318-10.176c-11.864-12.394-32.634-25.751-48.012-42.064c-2.26,9.295-5.78,17.813-6.822,24.408c-1.384,8.748-1.486,16.841-1.19,24.633c3.607,7.351,20.851,53.064,21.882,55.501c1.122,2.652,13.73,35.901,15.75,40c0.62,1.258,1.399,3.698,2.203,6.554C1232.26,487.68,1241.016,490.238,1251.415,486.132z"/>
      <path id="ana_female_arm_left_back" class="female-back-arm-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M976.471,346.082c-0.654-4.221-2.973-10.122-4.623-17.745c-15.777,16.6-37.048,30.136-48.632,42.729c-2.809,14.561-8.512,33.735-12.216,43.767c-4,10.833-11.333,33.333-15.333,47.333c-0.271,0.947-0.549,1.88-0.832,2.804c2.254,13.99,11.088,17.709,19.832,21.162c9.813,3.874,18.164,1.82,24.583-5.67c2.101-7.862,12.472-30.725,17.084-43.129c4.833-13,21.75-56.583,22.666-59.333c0.261-0.782,0.541-1.306,0.817-1.608C980.569,358.587,977.405,352.102,976.471,346.082z"/>
      <path id="ana_female_elbow_right_back" class="female-back-elbow-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1260.719,517.093c17.666-5.388,26.376-3.416,31.051,0.222c-3.516-10.316-7.056-17.938-8.02-19.564c-1.571-2.652-5.5-13.5-10-23.25c-0.994-2.155-1.924-4.77-2.842-7.725c-2.701,12.514-11.138,16.058-19.493,19.357c-10.399,4.106-19.155,1.547-25.712-7.079c1.814,6.445,3.757,15.015,4.297,16.946c1.396,4.992,3.128,13.187,4.25,18.25c0.746,3.363,1.607,9.433,4.883,20.598C1241.495,525.172,1254.375,519.027,1260.719,517.093z"/>
      <path id="ana_female_elbow_left_back" class="female-back-elbow-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M914.667,486.132c-8.744-3.453-17.578-7.172-19.832-21.162c-3.906,12.737-8.939,23.293-12.669,31.529c-2.147,4.742-5.686,12.941-8.969,21.789c4.363-4.216,12.979-7.047,32.166-1.196c6.256,1.908,18.88,7.908,21.492,17.354c2.269-8.253,4.666-21.363,6.145-25.947c1.666-5.167,4.666-20.333,6-27c0.062-0.306,0.149-0.66,0.25-1.038C932.831,487.953,924.479,490.006,914.667,486.132z"/>
      <path id="ana_female_forearm_right_back" class="female-back-forearm-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1313.271,621.668c-6.903-23.146-12.489-67.07-15.521-82.418c-1.548-7.837-3.76-15.422-5.98-21.936c-4.675-3.638-13.385-5.609-31.051-0.222c-6.344,1.934-19.224,8.08-21.586,17.755c1.655,5.645,3.927,12.588,7.117,21.152c9.197,24.688,23.002,50.249,35.914,77.07c0.09,0.187,0.26,0.683,0.494,1.392c5.866-0.635,13.676-2.687,18.501-4.877C1307.249,626.819,1311.627,624.546,1313.271,621.668z"/>
      <path id="ana_female_forearm_left_back" class="female-back-forearm-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M905.363,517.093c-19.187-5.851-27.803-3.021-32.166,1.196c-2.832,7.635-5.474,15.751-6.863,22.545c-3,14.667-3.834,31.167-6.167,46.5c-1.317,8.659-4.655,21.355-8.076,31.76c0.04,4.226,5.042,6.953,12.832,10.491c4.603,2.09,11.921,4.052,17.679,4.78c5.512-11.326,14.676-28.423,20.898-41.531c7.833-16.5,20-47.833,22.5-55.5c0.281-0.864,0.567-1.838,0.855-2.886C924.243,525,911.619,519,905.363,517.093z"/>
      <path id="ana_female_wrist_right_back" class="female-back-wrist-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1315.5,628.25c-0.757-1.922-1.5-4.138-2.229-6.582c-1.644,2.878-6.021,5.151-12.111,7.917c-4.825,2.191-12.635,4.242-18.501,4.877c0.587,1.78,1.59,4.951,2.842,8.288c1.156,3.082,2.127,7.941,2.679,12.28c7.985-5.969,25.61-13.901,37.272-15.479C1321.674,637.721,1318.034,634.687,1315.5,628.25z"/>
      <path id="ana_female_wrist_left_back" class="female-back-wrist-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M852.091,619.094c-2.638,8.02-5.324,14.679-7.175,17.073c-0.801,1.037-2.903,2.156-5.535,3.241c10.734,1.056,27.465,8.22,36.476,14.186c0.177-2.485,0.761-5.597,3.311-11.76c0.822-1.987,2.008-4.538,3.435-7.469c-5.758-0.728-13.076-2.69-17.679-4.78C857.133,626.047,852.131,623.319,852.091,619.094z"/>
      <path id="ana_female_hand_right_back" class="female-back-hand-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1364,661.25c-2.167-1.833-3.25-5.75-5.166-6.583c-4.294-1.868-5.833-3.5-10.167-5.667c-2.845-1.422-7.706-4.102-16-6.833c-2.291-0.755-4.781-1.438-7.216-2.616c-11.662,1.579-29.287,9.511-37.272,15.479c0.284,2.231,0.459,4.328,0.487,5.97c0.083,4.833,2.75,18,3.25,22.417s0.501,11.75,3.001,17.583s1.5,11.084,3.333,14.084s2.25,6.25,5.167,11.333s5.166,5.583,6.416,2.166s0-8.167-0.833-9.917s-1.917-7.582-2.334-10.416s-1.583-7.583-0.75-9.833s3.167,2.417,3.334,4.5s1.416,12.333,2.083,16.333s2.25,14.083,3.417,19.083s4.5,8.584,6.833,7.084s2.75-6.834,2.583-9.084s-1.416-12.499-1.583-15.583s-1.666-15.75-1.916-17.5s2.25-1.583,2.75,0.584c0.5,2.166,2.083,9.583,2.833,13c0.75,3.416,2.916,15.416,3.666,21.666s4.917,10.25,7.5,10.417s3.084-6.667,3.167-9.167s-1-12.5-1.5-15.333s-4.25-24.833-3.167-24.833s3.584,14.75,4.834,21.25s2.75,13,6.667,13.083s3.25-4.833,3.416-12.75s-0.75-13.917-2.333-25.25s-3.333-17.917-4.417-22s-3.166-10.75-2.083-12.583s7.5,1.667,11.333,5.167s10.834,4.5,13.25,2.5S1366.167,663.083,1364,661.25z"/>
      <path id="ana_female_hand_left_back" class="female-back-hand-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M824.75,644.167c-5.917,1.667-7.083,4.167-11.833,6.417s-9.75,6.167-10.417,7.25s-2.75,2.917-5.166,5.667c-2.417,2.75-0.834,5.583,1.833,6.417s7.75-0.5,11.25-3.333s6.75-4.667,9.167-5.75c2.416-1.083,3.416,0.417,3.666,2.083s-2.583,9.583-4,14s-1.416,6.833-1.916,10.5s-2.584,12.917-3.25,17.667c-0.667,4.75-1.418,14.416-1.5,19.25c-0.084,4.834,0.832,8.75,3.332,9.083s4.584-2,5.334-5.667s2.5-10.084,2.916-13.834c0.417-3.75,2.584-12.666,3.084-14.332c0.5-1.667,2.084-1.084,1.834,0.35c-0.25,1.435-1,5.482-1.5,8.15c-0.5,2.666-2.417,13.5-3.084,17.166s-1.5,14-1.334,18.167c0.167,4.167,2.834,6,5.168,4.833c2.333-1.167,4.582-6.5,5.332-10.75s2.168-10,3-15.25c0.834-5.25,2.918-15.083,4-18.583c1.084-3.5,2.418-0.917,2.334,0.917c-0.084,1.833-0.834,4.333-1,8.916s-1,15.584-1.5,19.834s-1.416,11,1.584,12.166s5.166-2.5,6.416-6.166s2.417-12.084,3.417-16.75c1-4.667,2.083-15.334,2.833-18.834s2.084-6.25,2.917-5.666c0.833,0.583,0.75,3.916,0,7.583s-1.667,9.75-2.667,13.5s-3.25,10.083,0,11.417s5.584-3.917,7-7.584s2.917-6.416,4.084-11c1.166-4.584,1.666-3.375,3.416-12.066c1.75-8.69,1.375-11.309,3.125-21.059s2.709-17.208,3.041-21.208c0.121-1.45,0.091-2.661,0.19-4.074c-9.011-5.966-25.741-13.13-36.476-14.186C834.767,641.309,828.518,643.105,824.75,644.167z"/>
      <path id="ana_female_hamstring_right" class="female-back-hamstring-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1093.217,740.125c0.668,1.323,0.02,1.214-0.05,3.376c-0.083,2.583-0.833,6.832-0.333,16.332s3,33.5,6.166,48.667s3.5,45.5,3.166,59.333c-0.333,13.833-1.832,43.333-2,53c-0.1,5.755,0.215,21.138,0.662,35.072c8.068,8.271,22.294,10.98,36.449,10.092c13.415-0.841,28.719-4.327,41.309-20.48c1.438-8.746,2.678-16.652,3.081-21.016c1-10.833,4.5-31.167,6.5-44.667s9.166-56.5,11.5-71.5s8.333-44.334,9.833-70c0.396-6.763,0.437-13.557,0.238-20.24C1180.306,756.63,1131.635,775.946,1093.217,740.125z"/>
      <path id="ana_female_hamstring_left" class="female-back-hamstring-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M955.291,716.701c-0.029,2.288-0.041,4.48-0.041,6.549c0,17.5,1.75,35.25,3.75,48s13.25,79.25,16,96.25c2.169,13.408,7.447,52.006,11.381,76.551c12.795,17.416,28.61,21.08,42.423,21.946c13.647,0.857,27.359-1.631,35.557-9.223c0.568-15.524,0.901-32.5,0.64-40.274c-0.5-14.833-2.25-56-2.125-67.875s2.625-35,4.25-43.75s4.959-31.457,5.209-41.624s0-17-0.167-20c-0.062-1.098-0.042-1.912,0.078-2.55C1033.463,776.164,984.449,755.951,955.291,716.701z"/>
      <path id="ana_female_knee_right_back" class="female-back-knee-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1137.277,965.997c-14.155,0.889-28.381-1.82-36.449-10.092c0.304,9.472,0.668,18.274,1.006,22.928c0.833,11.5,5.832,24.167,5.832,40.667c0,3.606-0.085,6.959-0.251,10.211c5.991-5.396,22.928-10.366,35.706-11.734c11.358-1.217,24.285,0.894,30.771,10.978c-0.72-4.93-1.486-9.509-2.059-13.453c-1.5-10.333-0.334-21.167,1.166-34.334c0.896-7.864,3.456-22.682,5.586-35.65C1165.996,961.67,1150.692,965.156,1137.277,965.997z"/>
      <path id="ana_female_knee_left_back" class="female-back-knee-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1028.804,965.997c-13.813-0.866-29.628-4.53-42.423-21.946c1.054,6.575,2.012,12.146,2.786,15.949c3.667,18,2.667,23.833,4.167,33s0.833,17.5-1.667,33.5c-0.191,1.223-0.35,2.636-0.485,4.176c6.072-11.57,19.8-13.982,31.779-12.699c12.366,1.323,28.625,6.021,35.092,11.214c-0.223-4.039-0.335-7.883-0.386-11.357c-0.167-11.5,3.5-22.5,4.833-30.333c0.635-3.728,1.344-16.629,1.86-30.726C1056.163,964.366,1042.451,966.854,1028.804,965.997z"/>
      <path id="ana_female_calf_right" class="female-back-calf-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1143.121,1017.977c-12.778,1.368-29.715,6.339-35.706,11.734c-0.594,11.627-2.289,21.885-5.415,37.122c-4,19.5-2.166,39.5-0.166,53.834s3,38.5,6.166,63.5c0.523,4.132,1.001,8.069,1.438,11.864c4.719,8.662,14.691,13.389,24.104,12.99c8.9-0.377,19.338-2.587,24.836-8.479c3.605-15.573,7.638-32.348,9.79-44.542c4-22.666,5.5-50.667,5.5-59.167s2.166-28.333,2.333-42.333c0.104-8.655-0.94-17.563-2.107-25.547C1167.406,1018.87,1154.479,1016.76,1143.121,1017.977z"/>
      <path id="ana_female_calf_left" class="female-back-calf-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M991.182,1030.676c-1.645,18.618,0.485,58.332,0.485,68.491c0,11,3.167,52.833,8,69.666c2.067,7.199,4.53,18.583,6.868,30.301c5.065,6.956,16.429,9.481,26.006,9.888c9.072,0.384,18.664-3.994,23.571-12.067c0.954-9.137,1.891-17.886,2.388-23.787c1.333-15.833,6.334-57.667,7-66.834s-0.166-34.5-3.5-48c-2.326-9.421-3.433-19.813-3.947-29.143c-6.467-5.192-22.726-9.891-35.092-11.214C1010.981,1016.693,997.254,1019.105,991.182,1030.676z"/>
      <path id="ana_female_ankle_right_back" class="female-back-ankle-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1109.438,1196.031c2.207,19.166,3.259,34.28,2.563,47.636c-0.408,7.827-3.25,15-5.416,22.25c-2.167,7.25-0.167,12.749,1.166,16.666s1.584,7,0.584,11.5s1.25,14.084,1.416,19.084c0.101,3.019,0.029,7.617,1.324,12.31c5.011-8.101,30.007-8.45,35.653,6.138c0.716-0.778,1.365-1.59,1.947-2.401c0.425-6.792,0.573-15.504,0.342-18.777c-0.809-11.434-1.615-18.82,1.506-29.082c-0.119-0.115-0.241-0.226-0.355-0.353c-1.5-1.667-1-7.333-1.333-10.667s0.5-18.833,2.666-36c1.001-7.932,3.781-20.418,6.877-33.791c-5.498,5.892-15.936,8.102-24.836,8.479C1124.129,1209.42,1114.156,1204.693,1109.438,1196.031z"/>
      <path id="ana_female_ankle_left_back" class="female-back-ankle-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1032.541,1209.021c-9.577-0.406-20.94-2.932-26.006-9.888c3.129,15.682,6.034,31.961,7.465,39.616c2.5,13.375,2.667,35.916,2.084,40c-0.145,1.009-0.375,1.654-0.679,2.092c3.308,10.532,2.482,17.97,1.66,29.594c-0.249,3.515-0.06,13.297,0.44,20.242c0.507,0.56,1.034,1.105,1.598,1.626c4.882-14.596,28.534-15.045,35.094-7.896c1.65-5.945,1.032-11.148,2.053-18.408c1.125-8-0.125-13.125-0.5-16.625s1.875-5.125,3.375-12.125s-1.125-12.875-3-19.625s-2.458-13.625-2.958-22.959c-0.313-5.855,1.34-22.334,2.945-37.712C1051.205,1205.027,1041.613,1209.405,1032.541,1209.021z"/>
      <path id="ana_female_heel_right" class="female-back-sole-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1111.074,1325.477c0.85,3.079,2.283,6.199,4.76,8.939c6.25,6.916,20.083,3.834,25.833,1.084c1.966-0.94,3.644-2.342,5.061-3.886C1141.081,1317.026,1116.085,1317.376,1111.074,1325.477z"/>
      <path id="ana_female_heel_left" class="female-back-sole-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1019.104,1332.304c4.011,3.708,9.41,6.354,16.021,6.821c10.625,0.75,16.125-6.125,18.5-12.875c0.22-0.625,0.403-1.236,0.572-1.842C1047.638,1317.259,1023.985,1317.708,1019.104,1332.304z"/>
      <path id="ana_female_foot_right_back" class="female-back-foot-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1149.017,1310.436c0.231,3.273,0.083,11.985-0.342,18.777c1.638-2.285,2.762-4.545,3.409-5.88c1.334-2.75,9.5-11.333,12.75-14.916s2.499-5.084,2.499-7.084s2-2,2.5-5.333s-1.833-4.833-2.916-5.583s-4.084-0.75-4.25-1.417s-1.25-1.917-3.167-2.917s-4,0.667-4.917-1.083c-0.847-1.617-2.613-2.247-4.061-3.646C1147.401,1291.615,1148.208,1299.002,1149.017,1310.436z"/>
      <path id="ana_female_foot_left_back" class="female-back-foot-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M1017.065,1310.436c0.822-11.624,1.647-19.062-1.66-29.594c-0.925,1.334-2.522,0.714-4.405,2.408c-1.34,1.206-1.584,2.583-3.834,2.667s-4.666,2.25-5.5,3.75c-0.833,1.5-2.749,0.25-4.916,1.416s-3,3.667-2.416,6.25c0.583,2.583,2.333,3.25,2.416,4.917s1,4.25,3.75,7.75s9.25,7.25,12.875,14.625c1.053,2.142,2.442,4.193,4.131,6.053C1017.006,1323.732,1016.816,1313.95,1017.065,1310.436z"/>
    </g>
    <g v-if="!sideBySide">
      <image overflow="visible" x="489" y="1026" width="149" height="274" href="/assets/img/rtm/female-front.png"></image>
      <rect x="489" y="1026" fill="transparent" stroke="#6a6a6a" vector-effect="non-scaling-stroke" width="149" height="274"/>
      <rect class="goa" x="489" y="1026" fill="#72B8FC" stroke="#6a6a6a" vector-effect="non-scaling-stroke" width="149" height="274"/>
    </g>
    <g id="anaspotsb"></g>
  </svg>
</template>

<script>
export default {
  name: 'FemaleBack',
  props: {
    sideBySide: Boolean,
  },
}
</script>
